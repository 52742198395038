import { Injectable } from '@angular/core';
import { IScores } from '@models/scores.model';
import { RestApiService } from '@services/rest-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/internal/operators/take';
import { PATH } from 'src/app/config/path.configs';

@Injectable({
    providedIn: 'root',
})
export class ScoresService {
    constructor(private readonly restApi: RestApiService) {}

    public getScores(questionnaireId: string): Observable<IScores> {
        return this.restApi
            .get<IScores>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SCORE}`
            )
            .pipe(take(1));
    }
}
