import { Injectable } from '@angular/core';
import { ISplash } from '@models/splash.model';
import { IAnswer } from '@shared/models';
import { Observable, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class SplashService {
    constructor(private readonly restApi: RestApiService) {}

    public getSplashById(
        questionnaireId: string,
        splashId: string
    ): Observable<ISplash> {
        return this.restApi
            .get<ISplash>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SPLASH}/${splashId}`
            )
            .pipe(take(1));
    }

    public submitAnswerbySplash(
        questionnaireId: string,
        splashId: string,
        body: IAnswer
    ): Observable<IAnswer | null> {
        return this.restApi
            .post<IAnswer>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SPLASH}/${splashId}/${PATH.ANSWER}`,
                body
            )
            .pipe(take(1));
    }

    public getAnswerSplashById(
        questionnaireId: string,
        splashId: string
    ): Observable<IAnswer> {
        return this.restApi
            .get<IAnswer>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SPLASH}/${splashId}/${PATH.ANSWER}`
            )
            .pipe(take(1));
    }
}
