<div class="wrapper">
    <div class="splash" *ngIf="splash$ | async; let splash">
        <app-paragraph class="text-left" [text]="splash.text"></app-paragraph>
        <app-dynamic-form
            *ngIf="convertedAnswerSetting$ | async; let answerSettings"
            [answers]="answerSettings"
            [questionId]="splash.id"
            [nextButton]="{
                disabled: false,
                type: 'filledPrimary',
                icon_name: 'navigate_next',
                isWhiteIcon: true,
                isOutlined: true,
                isIconAfterLabel: true,
                isIconBeforeLabel: false,
                text: nextQuestionText
            }"
            [backButton]="{
                disabled: !screen.previousScreenId,
                type: 'outlinedPrimary',
                icon_name: 'navigate_before',
                isWhiteIcon: true,
                isOutlined: false,
                isIconBeforeLabel: true,
                text: previousQuestionText
            }"
            (navigateBack)="handleNavigateBack($event)"
            (submitting)="handleSubmit($event)"
        ></app-dynamic-form>
    </div>
</div>

<app-battery-copyright
    *ngIf="copyright$ | async; let copyright"
    [copyright]="copyright"
></app-battery-copyright>

<app-battery-tool
    *ngIf="
        (questionnaireService.questionnaireSubject | async)?.batteries;
        let batteries
    "
    [batteries]="batteries"
    [activeBatteryId]="(splash$ | async)?.batteryId"
></app-battery-tool>
