import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-paragraph',
    styles: [
        '.small {margin-top: 1.5em; font-size: 0.57em; line-height: 150%; letter-spacing: 0.5px;}',
        '.question-small-text {margin-bottom: 1em; font-size: 0.75em; line-height: 150%; letter-spacing: 0.15px;}',
        '.question-big-centered-text {margin-bottom: 0.55em; font-size: 1.27em; line-height: 127%; letter-spacing: 0.5px; text-align: center;}',
    ],
    templateUrl: './paragraph.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ParagraphComponent {
    @Input() text!: string | null;

    constructor(public sanitizer: DomSanitizer) {}
}
