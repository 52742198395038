import { AnswerBase } from './answer-base';

export class RangeAnswer extends AnswerBase<string> {
    override controlType = 'range';

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options' as keyof typeof options] || [];
    }
}
