<div class="wrapper">
    <div class="consent" *ngIf="consentSubject$ | async; let consentSubject">
        <app-paragraph [text]="consentSubject?.text"></app-paragraph>

        <form [formGroup]="form" class="consent-form small-width">
            <fieldset>
                <legend>Select an option: I consent or I do not consent</legend>
                <mat-radio-group
                    role="list"
                    color="primary"
                    aria-label="Select an option: consent or do not consent"
                    formControlName="consent"
                    class="consent-form-radio-buttons"
                >
                    <mat-radio-button
                        *ngFor="
                            let option of consentOptions;
                            index as i;
                            trackBy: trackByFn
                        "
                        role="listitem"
                        [value]="option.value"
                    >
                        {{ option.label }}
                    </mat-radio-button>
                </mat-radio-group>
            </fieldset>
            <mat-form-field
                class="text-box full-width"
                appearance="outline"
                [style.fontSize]="fontsizeTextAria"
            >
                <mat-label>{{ nameFormControlLabel }}</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>

            <section class="consent-form-checkbox">
                <mat-checkbox
                    role="checkbox"
                    color="primary"
                    aria-label="Accept as a signature"
                    formControlName="signature"
                >
                    {{ consentSubject?.aceptSignatureLabel }}
                </mat-checkbox>
            </section>
        </form>

        <div class="inline-wrapper">
            <app-button
                type="filledPrimary"
                [icon_name]="'navigate_next'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="true"
                [isIconBeforeLabel]="false"
                (appClick)="onSubmit()"
            >
                Submit
            </app-button>
            <app-button
                type="outlinedPrimary"
                [icon_name]="'navigate_before'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="false"
                [isIconBeforeLabel]="true"
                (appClick)="handleNavigateBack($event)"
            >
                Back
            </app-button>
        </div>
    </div>
</div>
