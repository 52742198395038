<div class="wrapper">
    <div
        [ngClass]="eq5dCompliant ? 'content-eq5d' : 'content'"
        *ngIf="question$ | async; let question"
    >
        <app-paragraph
            class="text-left"
            [ngClass]="{
                'm-top':
                    eq5dCompliant &&
                    !!question?.answerSettings?.values?.range?.maxValueLabel
            }"
            [text]="question.text"
        ></app-paragraph>

        <app-dynamic-form
            *ngIf="convertedAnswerSetting$ | async; let answerSettings"
            [answers]="answerSettings"
            [questionId]="question.id"
            [nextButton]="{
                disabled: false,
                type: 'filledPrimary',
                icon_name: 'navigate_next',
                isWhiteIcon: true,
                isOutlined: true,
                isIconAfterLabel: true,
                isIconBeforeLabel: false,
                text: nextQuestionText
            }"
            [backButton]="{
                disabled: !screen.previousScreenId,
                type: 'outlinedPrimary',
                icon_name: 'navigate_before',
                isWhiteIcon: true,
                isOutlined: false,
                isIconBeforeLabel: true,
                text: previousQuestionText
            }"
            (navigateBack)="handleNavigateBack($event)"
            (submitting)="handleSubmit($event)"
        ></app-dynamic-form>
    </div>
</div>

<app-battery-copyright
    *ngIf="copyright$ | async; let copyright"
    [copyright]="copyright"
></app-battery-copyright>

<app-battery-tool
    *ngIf="
        (questionnaireService.questionnaireSubject | async)?.batteries;
        let batteries
    "
    [batteries]="batteries"
    [activeBatteryId]="(question$ | async)?.batteryId"
></app-battery-tool>
