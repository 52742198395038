import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { EnvConfigurationService } from '@core/service';
import { HttpModule } from '@core/http';
import { SharedModule } from '@shared';
import { HomeComponent } from './shared/pages/home/home.component';
import { ChangeFavIconDirective } from '@shared/directives';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StatusPageModule } from '@components/status-message';
import { FaqComponent } from '@pages/faq/faq.component';
import { SmsLandingComponent } from '@pages/sms-landing/sms-landing.component';
import { ButtonModule } from '@components/button';
import { UpdateContactPreferencesComponent } from '@pages/sms-landing/update-contact-preferences.component';
import { SmsDeclineComponent } from '@pages/sms-landing/sms-decline/sms-decline.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const initApp = (appLoadService: EnvConfigurationService) => () =>
    appLoadService.setConfig();
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ChangeFavIconDirective,
        FaqComponent,
        SmsLandingComponent,
        SmsDeclineComponent,
        UpdateContactPreferencesComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpModule,
        SharedModule,
        StatusPageModule,
        ButtonModule,
        // ngx-translate and the loader module
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [EnvConfigurationService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
