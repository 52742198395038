import { Injectable, OnDestroy } from '@angular/core';
import { IMetaData } from '@shared/models';
import { BehaviorSubject, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';
import { LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root',
})
export class MetaDataService implements OnDestroy {
    private metaDataSubject = new BehaviorSubject<IMetaData>({} as IMetaData);
    public metaData$ = this.metaDataSubject.asObservable();

    constructor(
        private readonly restApi: RestApiService,
        private loggingService: LoggingService
    ) {}

    public fetchMetaData(): void {
        this.restApi
            .get<IMetaData>(`${PATH.METADATA}`)
            .pipe(take(1))
            .subscribe({
                next: (metaData: IMetaData) => {
                    this.metaDataSubject.next(metaData);
                },
                error: error => {
                    const staticMetaData: IMetaData = {
                        copyright: `<p>&copy; ${new Date().getFullYear()} CODE Technology</p>`,
                    };
                    this.metaDataSubject.next(staticMetaData);
                    this.loggingService.logError(null, error);
                },
            });
    }

    ngOnDestroy(): void {
        this.metaDataSubject.complete();
        this.metaDataSubject.unsubscribe();
    }
}
