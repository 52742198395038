<div class="wrapper">
    <div class="content" *ngIf="question$ | async; let question">
        <app-paragraph
            class="text-left m-12"
            [text]="question.text"
        ></app-paragraph>

        <app-dynamic-form
            *ngIf="convertedAnswerSetting$ | async; let answerSettings"
            [answers]="answerSettings"
            [questionId]="question.id"
            [nextButton]="{
                disabled: false,
                type: 'filledPrimary',
                icon_name: 'navigate_next',
                isWhiteIcon: true,
                isOutlined: true,
                isIconAfterLabel: true,
                isIconBeforeLabel: false,
                text: submitSurveyText
            }"
            [backButton]="{
                disabled: !screen.previousScreenId,
                type: 'outlinedPrimary',
                icon_name: 'navigate_before',
                isWhiteIcon: true,
                isOutlined: false,
                isIconBeforeLabel: true,
                text: previousQuestionText
            }"
            (navigateBack)="handleNavigateBack($event)"
            (submitting)="handleSubmit($event)"
        ></app-dynamic-form>
    </div>
    <app-spinner></app-spinner>
</div>
