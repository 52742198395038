import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { finalize, Observable, tap, first } from 'rxjs';
import { EnvConfigurationService, IAppConfiguration } from '@core/service';
import { ILogData } from '@models/log.model';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    private isStubMode: boolean = false;
    constructor(
        private envConfig: EnvConfigurationService,
        private loggingService: LoggingService,
        private questionnaireIdService: QuestionnaireIdService
    ) {
        this.envConfig.configuration$
            .pipe(first())
            .subscribe((res: IAppConfiguration) => {
                this.isStubMode = res.isStubMode ?? res.isStubMode;
            });
    }
    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const startTime = Date.now();
        let response: HttpResponse<unknown>;
        let status: number;

        return next.handle(req).pipe(
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                        response = event as HttpResponse<unknown>;
                    }
                },

                error: err => {
                    status = err;
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: err,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            }),
            finalize(() => {
                const elapsedTime = Date.now() - startTime;
                this.printDetails(
                    req.method,
                    req.urlWithParams,
                    response.status,
                    response.statusText,
                    elapsedTime,
                    req.body,
                    response.body
                );
            })
        );
    }

    private printDetails(
        method: string,
        urlWithParams: string,
        status: number,
        statusText: string,
        elapsedTime: number,
        reqBody: unknown,
        responseBody: unknown
    ) {
        if (!this.isStubMode) {
            return;
        }

        console.groupCollapsed(
            method.toUpperCase(),
            urlWithParams,
            status,
            statusText,
            `in ${elapsedTime} ms`
        );
        if (reqBody) {
            console.log('REQUEST: ', reqBody);
        }
        console.log('RESPONSE: ', responseBody);
        console.groupEnd();
    }
}
