import { Component, OnDestroy, OnInit } from '@angular/core';
import { OFFLINE_STATUS_MESSAGE } from '@shared/constants';
import { DefaultColorList } from '@shared/constants/default-theme';
import { MetaDataService } from '@shared/services';
import { changeTheme } from '@shared/utility';
import {
    fromEvent,
    map,
    merge,
    of,
    Subject,
    Subscription,
    takeUntil,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    networkStatus: boolean = false;
    networkStatusSub$: Subscription = Subscription.EMPTY;
    public OFFLINE_STATUS_MESSAGE = OFFLINE_STATUS_MESSAGE;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public copyright: string = '';

    constructor(
        private metaDataService: MetaDataService,
        private translate: TranslateService
    ) {
        changeTheme(
            DefaultColorList.PrimaryColor,
            DefaultColorList.SecondaryColor,
            DefaultColorList.LightPrimaryColor
        );

        this.copyright = `<p>&copy; ${new Date().getFullYear()} CODE Technology</p>`;
    }

    ngOnInit(): void {
        this.networkStatus = navigator.onLine;
        this.networkStatusSub$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(
                takeUntil(this.destroy$),
                map(() => navigator.onLine)
            )
            .subscribe((status: boolean) => {
                this.networkStatus = status;

                if (status) {
                    this.metaDataService.fetchMetaData();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
