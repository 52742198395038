import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ILogData } from '@models';
import {
    IResearchSubjectInformationAndConsentForm,
    ISubmitResearchSubjectInfo,
} from '@models/consent.model';
import { IDynamicComponent } from '@pages/dynamic-page/dynamic.component';
import { ConsentService } from '@services/consent.service';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { AsyncSubject } from 'rxjs/internal/AsyncSubject';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
    selector: 'app-research-subject-info',
    templateUrl: './research-subject-info.component.html',
    providers: [ConsentService],
})
export class ResearchSubjectInfoComponent
    implements IDynamicComponent, OnDestroy, OnInit
{
    @Input() screen: any;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public researchSubjInfo$: AsyncSubject<IResearchSubjectInformationAndConsentForm> =
        new AsyncSubject<IResearchSubjectInformationAndConsentForm>();
    private startTimestamp: Date = new Date();

    constructor(
        public questionnaireIdService: QuestionnaireIdService,
        private consentService: ConsentService,
        private loggingService: LoggingService
    ) {}

    ngOnInit(): void {
        this.consentService
            .getResearchSubjInfo(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: IResearchSubjectInformationAndConsentForm) => {
                    if (response) {
                        this.researchSubjInfo$.next(response);
                        this.researchSubjInfo$.complete();
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.researchSubjInfo$.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public handleNavigateForward(event: any) {
        this.navigateForward.emit(event);
    }

    public handleNavigateBack(event: any) {
        this.navigateBack.emit(event);
    }

    public onSubmit() {
        const body: ISubmitResearchSubjectInfo = {
            questionnaireId: this.questionnaireIdService.questionnaireId,
            startTimestamp: this.startTimestamp.toJSON(),
            submitTimestamp: new Date().toJSON(),
            userAgent: window.navigator.userAgent,
        };

        this.consentService
            .submitResearchSubjInfo(
                this.questionnaireIdService.questionnaireId,
                body
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('researchsubjectinfo');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }
}
