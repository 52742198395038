import { Directive, OnDestroy } from '@angular/core';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { IBranding, ILogData } from '@shared/models';
import { BrandingService } from '@shared/services/branding.service';
import { Subject, takeUntil } from 'rxjs';

@Directive({ selector: '[appFavIcon]' })
export class ChangeFavIconDirective implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    favIcon: HTMLLinkElement = document.querySelector(
        '#favIcon'
    ) as HTMLLinkElement;

    constructor(
        public brandingService: BrandingService,
        private loggingService: LoggingService,
        private questionnaireIdService: QuestionnaireIdService
    ) {
        this.brandingService.brandingSubject
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (branding: IBranding) => {
                    if (branding?.faviconUrl) {
                        this.favIcon.href = branding.faviconUrl;
                    }
                },
                error: error => {
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
