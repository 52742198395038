import { Injectable } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AnswerBase, IAnswerOptions } from '@models';
import { FieldType } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class AnswerControlService {
    form: FormGroup = new FormGroup({});
    arrayContols: FormArray = this.fb.array([]);

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({});
    }

    toFormGroup(answerControls: AnswerBase<string>[]) {
        answerControls.forEach(control => {
            if (
                (control.controlType as FieldType) === FieldType.List &&
                control.isMultiselect
            ) {
                if (!control.options || !control.options.length) {
                    this.arrayContols.push(
                        new FormControl(null, {
                            nonNullable: true,
                        })
                    );
                } else {
                    control.options.forEach((element: IAnswerOptions) => {
                        this.arrayContols.push(
                            new FormControl(
                                { ...element },
                                {
                                    nonNullable: true,
                                }
                            )
                        );
                    });
                }

                this.form.addControl(control.controlType, this.arrayContols);
            } else {
                this.form.addControl(
                    control.key,
                    control.required
                        ? new FormControl(
                              {
                                  value:
                                      control.value != null
                                          ? control.value
                                          : null,
                                  disabled: control.disabled,
                              },
                              {
                                  validators: [Validators.required],
                                  updateOn:
                                      (control.controlType as FieldType) ===
                                      FieldType.Range
                                          ? 'change'
                                          : 'blur',
                              }
                          )
                        : new FormControl({
                              value: control.value || null,
                              disabled: control.disabled,
                          })
                );
            }
        });
        return this.form;
    }
}
