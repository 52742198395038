import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDynamicComponent } from '@shared/pages/dynamic-page';

@Component({
    selector: 'app-score',
    templateUrl: './score.component.html',
})
export class ScoreComponent implements IDynamicComponent {
    @Input() screen: any;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();
}
