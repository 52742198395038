<div appFavIcon class="container">
    <header role="header" class="border-bottom">
        <mat-toolbar id="banner" role="banner" color="primary">
            <img
                *ngIf="
                    (brandingService.brandingSubject | async)?.logoUrl;
                    else code_logo;
                    let logo
                "
                alt="{{ clientName$ | async }}"
                aria-label="Company logo"
                src="{{ logo }}"
                (error)="errorHandler($event)"
            />
            <ng-template #code_logo>
                <img
                    class="w-60"
                    aria-label="Code Technology logo"
                    alt="Code Technology logo"
                    src="../../../../assets/img/code_logo.svg"
                />
            </ng-template>
        </mat-toolbar>
    </header>
    <main role="main">
        <div class="mobile-landing-wrapper">
            <div
                *ngIf="
                    (brandingService.brandingSubject | async)?.logoUrl;
                    else not_found
                "
            >
                <section>
                    <h1 class="centered-title">{{ 'proctor.sms.updatecontactpreferences.page-title' | translate }}</h1>
                </section>
                <section id="change-contact-preference">
                    <div
                        [formGroup]="reactiveForm"
                        method="post"
                        id="contact-preferences-form"
                    >
                        <p innerHTML="{{ 'proctor.sms.updatecontactpreferences.intro' | translate }}"></p>
                        <p innerHTML="{{ 'proctor.sms.updatecontactpreferences.nochanges' | translate }}"></p>

                        <div class="email-input-wrapper">
                            <mat-form-field
                                appearance="outline"
                                class="add-email-box"
                            >
                                <input
                                    class="email-input-font"
                                    name="email"
                                    type="email"
                                    matInput
                                    [formControl]="
                                        reactiveForm.controls.emailAddress
                                    "
                                    [errorStateMatcher]="matcher"
                                    maxlength="100"
                                    [(ngModel)]="email"
                                />
                                <mat-label>{{ 'proctor.sms.updatecontactpreferences.placeholder' | translate }}</mat-label>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.emailAddress.hasError(
                                            'email'
                                        )
                                    "
                                >
                                    {{ 'proctor.sms.updatecontactpreferences.valid-email' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.confirmEmailAddress.hasError(
                                            'maxlength'
                                        )
                                    "
                                >
                                    Email too long
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.emailAddress.hasError(
                                            'maxlength'
                                        )
                                    "
                                >
                                    {{ 'proctor.sms.updatecontactpreferences.email-too-long' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <mat-form-field
                                appearance="outline"
                                class="add-email-box"
                            >
                                <input
                                    class="email-input-font"
                                    name="confirm-email"
                                    type="email"
                                    matInput
                                    [formControl]="
                                        reactiveForm.controls
                                            .confirmEmailAddress
                                    "
                                    [errorStateMatcher]="matcher"
                                    maxlength="100"
                                    onPaste="return false"
                                    autocomplete="off"
                                />
                                <mat-label>{{ 'proctor.sms.updatecontactpreferences.confirmplaceholder' | translate }}</mat-label>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.confirmEmailAddress.hasError(
                                            'email'
                                        )
                                    "
                                >
                                    {{ 'proctor.sms.updatecontactpreferences.valid-email' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.confirmEmailAddress.hasError(
                                            'maxlength'
                                        )
                                    "
                                >
                                    {{ 'proctor.sms.updatecontactpreferences.email-too-long' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        reactiveForm.controls.confirmEmailAddress.hasError(
                                            'notMatch'
                                        )
                                    "
                                >
                                    {{ 'proctor.sms.updatecontactpreferences.email-not-match' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div [innerHTML]="errorMessage"></div>

                        <div class="inline-wrapper">
                            <app-button
                                type="filledPrimary"
                                [icon_name]="'navigate_next'"
                                [isIconAfterLabel]="true"
                                (appClick)="onSubmit()"
                            >
                                {{ 'proctor.navigation.next' | translate }}
                            </app-button>
                        </div>
                    </div>
                </section>
            </div>
            <ng-template #not_found>
                <div
                    class="wrapper"
                    style="
                        min-height: calc(
                            100vh - 10.875rem - 1.25rem - 1.5rem - 0.25rem
                        );
                    "
                >
                    <div class="content-status-page">
                        <div
                            class="not-found-text"
                            [innerHTML]="NOT_FOUND_PAGE_CONTENT"
                        ></div>
                    </div>
                </div>
            </ng-template>
        </div>
    </main>
    <footer role="contentinfo">
        <section
            role="region"
            [innerHtml]="(metaDataService.metaData$ | async)?.copyright"
            aria-label="Copyright CODE Technology"
        ></section>

        <a
            href="https://www.w3.org/WAI/WCAG2AA-Conformance"
            [title]="('proctor.wcag-explanation' | translate)"
            target="_blank"
        >
            <img
                aria-label="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
                src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-blue-v"
                alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
            />
        </a>
    </footer>
</div>
