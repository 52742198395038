import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    @Input() disabled = false;
    @Input() isWhiteIcon = true;
    @Input() type:
        | 'filledPrimary'
        | 'filledSecondary'
        | 'outlinedPrimary'
        | 'transparentPrimary'
        | 'inactive' = 'filledPrimary';
    @Input() size: 'small' | 'normal' | 'big' = 'normal';
    @Input() icon_name: string = '';
    @Input() isOutlined?: boolean = true;
    @Input() isIconBeforeLabel?: boolean = false;
    @Input() isIconAfterLabel?: boolean = false;
    classMap = {
        filledPrimary: 'filled-primary',
        filledSecondary: 'filled-secondary',
        outlinedPrimary: 'outlined-primary',
        transparentPrimary: 'transparent-primary',
        inactive: 'inactive',
    };

    @Output() appClick = new EventEmitter();

    get buttonTypeClass() {
        return this.classMap[this.type] ?? 'filledPrimary';
    }

    handleClick(e: unknown) {
        if (this.disabled) {
            return;
        }
        this.appClick.emit(e);
    }
}
