import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NOT_FOUND_PAGE_CONTENT, StatusType } from '@shared/constants';
import { ILogData } from '@models';
import { LoggingService } from '@services/logging.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private loggingService: LoggingService) {}
    intercept<T>(
        req: HttpRequest<T>,
        next: HttpHandler
    ): Observable<HttpEvent<T>> {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        return new Observable<HttpEvent<T>>(originalSubscriber => {
            const sub = next.handle(req).subscribe({
                next: data => {
                    originalSubscriber.next(data);
                },
                error: err => {
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: err,
                        submitTimestamp: new Date().toJSON(),
                    };
                    this.loggingService.logError(null, body);

                    switch (err.status) {
                        case 404:
                            originalSubscriber.error(err.error);
                            break;
                        case 403:
                            break;
                        case 500:
                            const data: any = {
                                status: StatusType.NotFound,
                                statusMessage: NOT_FOUND_PAGE_CONTENT,
                            };
                            originalSubscriber.error(data);
                            break;
                        default:
                            originalSubscriber.error(err);
                            break;
                    }
                },
                complete: () => originalSubscriber.complete(),
            });
            return () => sub.unsubscribe();
        });
    }
}
