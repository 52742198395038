import { Injectable } from '@angular/core';
import { IIntroduction } from '@shared/models';
import { Observable, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class IntroductionService {
    constructor(private readonly restApi: RestApiService) {}

    public getIntroduction(questionnaireId: string): Observable<IIntroduction> {
        return this.restApi
            .get<IIntroduction>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.INTRODUCTION}`
            )
            .pipe(take(1));
    }
}
