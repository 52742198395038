import { AnswerBase } from './answer-base';

export class ListAnswer extends AnswerBase<string> {
    override controlType = 'list';
    override options: {
        key: string;
        value: string;
        isCommentAvailable: boolean;
        isCommentMandatory: boolean;
        checked: boolean;
    }[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options' as keyof typeof options] || [];
    }
}
