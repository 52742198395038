import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashService, QuestionnaireService } from '@services';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { Subject, takeUntil } from 'rxjs';
import { IAnswer, ILogData, IQuestion } from '@models';
import { QuestionComponent } from '../question';
import { LoggingService } from '@services/logging.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-splash',
    templateUrl: './splash.component.html',
})
export class SplashComponent extends QuestionComponent {
    public splash$: Subject<IQuestion> = new Subject<IQuestion>();

    constructor(
        public splashService: SplashService,
        public override questionnaireService: QuestionnaireService,
        public override questionnaireIdService: QuestionnaireIdService,
        public override loggingService: LoggingService,
        public override readonly router: Router,
        translate: TranslateService
    ) {
        super(
            questionnaireService,
            questionnaireIdService,
            loggingService,
            router,
            translate
        );
    }

    protected override loadData(
        questionnaireId: string,
        splashId: string,
        prevAnswer: IAnswer | null = null
    ) {
        this.questionnaireService
            .getQuestionById(questionnaireId, splashId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (res: IQuestion) => {
                    this.splash$.next(res);

                    if (res) {
                        this.transformAnswer(
                            res.answerSettings?.values,
                            res.answerSettings?.validation,
                            prevAnswer
                        );

                        this.setCopyright(res.batteryId);
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    protected override handleSubmit(answer: IAnswer) {
        this.questionnaireService
            .submitAnswerbyQuestion(
                this.questionnaireIdService.questionnaireId,
                this.screen.dataId,
                answer
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('Splash');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }
}
