import { AnswerBase } from './answer-base';

export class TextboxAnswer extends AnswerBase<string> {
    override controlType = 'textbox';
    override type: string;
    pattern: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type' as keyof typeof options] || 'text';
        this.min = options['min' as keyof typeof options];
        this.max = options['max' as keyof typeof options];
        this.pattern = options['pattern' as keyof typeof options];
    }
}
