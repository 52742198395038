<div class="status-page">
    <div class="wrapper">
        <ng-container *ngIf="!isAdditionalContent">
            <div
                class="content-status-page"
                *ngIf="gratitude$ | async; let gratitude"
            >
                <app-paragraph [text]="gratitude"></app-paragraph>
            </div>
            <div
                *ngIf="
                    !!(questionnaireService.questionnaireSubject | async)
                        ?.displayScores && showScores
                "
                class="mt-8"
            >
                <app-scores></app-scores>
            </div>
        </ng-container>
        <ng-container *ngIf="isAdditionalContent">
            <ng-container *ngIf="!showScores">
                <div
                    class="content-status-page"
                    *ngIf="gratitude$ | async; let gratitude"
                >
                    <app-paragraph [text]="gratitude"></app-paragraph>
                    <app-video-player
                        [screenId]="screen?.id"
                        [videoId]="currentVideoId"
                        [text]="altVideoText"
                    ></app-video-player>

                    <div class="mt-40">
                        <div class="gap-12">
                            <span class="scores-title">Current scores</span>
                            <app-paragraph
                                [text]="
                                    '\<div class=small-text>You can see your recent scores resulted from your survey by clicking \<b>‘See Scores’\</b>.\</div>'
                                "
                            ></app-paragraph>
                        </div>
                        <div class="inline-wrapper">
                            <app-button
                                type="filledPrimary"
                                (appClick)="onSubmit()"
                            >
                                See Scores
                            </app-button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showScores">
                <div
                    *ngIf="
                        !!(questionnaireService.questionnaireSubject | async)
                            ?.displayScores && showScores
                    "
                >
                    <app-scores></app-scores>
                </div>
            </ng-container>
        </ng-container>
        <app-spinner></app-spinner>
    </div>
</div>
