import { ConsentSubjectComponent } from 'src/app/components/consent-subject';
import { GratitudeComponent } from 'src/app/components/gratitude';
import { IntroductionComponent } from 'src/app/components/introduction';
import { QuestionComponent } from 'src/app/components/question';
import { ResearchQuestionComponent } from 'src/app/components/research-question/research-question.component';
import { ResearchSubjectInfoComponent } from 'src/app/components/research-subject-info';
import { ScoreComponent } from 'src/app/components/score';
import { SplashComponent } from 'src/app/components/splash';
import { SubmitComponent } from 'src/app/components/submit';

export enum ComponentList {
    Gratitude = 'gratitude',
    Introduction = 'introduction',
    Question = 'question',
    Score = 'score',
    Splash = 'splash',
    Submit = 'submit',
    ResearchQuestion = 'researchquestion',
    SubjectConsent = 'subjectconsent',
    ResearchSubjectInfo = 'researchsubjectinfo',
}

export const COMPONENT_NAME_MAP: { [key: string]: any } = {
    [ComponentList.Gratitude]: GratitudeComponent,
    [ComponentList.Introduction]: IntroductionComponent,
    [ComponentList.Question]: QuestionComponent,
    [ComponentList.Score]: ScoreComponent,
    [ComponentList.Splash]: SplashComponent,
    [ComponentList.Submit]: SubmitComponent,
    [ComponentList.ResearchSubjectInfo]: ResearchSubjectInfoComponent,
    [ComponentList.SubjectConsent]: ConsentSubjectComponent,
    [ComponentList.ResearchQuestion]: ResearchQuestionComponent,
};
