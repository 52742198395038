export enum FieldType {
    Textbox = 'textbox',
    Textarea = 'textarea',
    List = 'list',
    Range = 'range',
}

export enum AnswerType {
    List = 'list',
    Range = 'range',
    Value = 'value',
}

export const ANSWER_NAME_MAP: { [key: string]: any } = {
    [AnswerType.List]: FieldType.List,
    [AnswerType.Range]: FieldType.Range,
    [AnswerType.Value]: FieldType.Textarea,
};

export const NOT_FOUND_PAGE_CONTENT: string = `We’re sorry, the page you were looking for could not be found.`;
export const OFFLINE_STATUS_MESSAGE = `Your device is offline. Please check your internet connection to continue the assessment.`;
