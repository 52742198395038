import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { NOT_FOUND_PAGE_CONTENT, StatusType } from '@shared/constants';
import {
    LoadingService,
    MetaDataService,
    QuestionnaireService,
} from '@services';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { BrandingService } from '@services/branding.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IBranding, ILogData } from '@models';
import { SmsLandingService } from '@services/sms-landing.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sms-landing',
    templateUrl: './sms-landing.component.html',
    styleUrls: ['./sms-landing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SmsLandingComponent implements OnInit, OnDestroy {
    public clientName$: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Code Technology Logo'
    );
    public currentStatusType: StatusType | null = null;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public introductoryText$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    public conclusion$: BehaviorSubject<string> = new BehaviorSubject<string>(
        ''
    );
    public notices$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(
        ''
    );
    errorMessage: string = '';

    constructor(
        public loadingService: LoadingService,
        public metaDataService: MetaDataService,
        private questionnaireIdService: QuestionnaireIdService,
        public questionnaireService: QuestionnaireService,
        public brandingService: BrandingService,
        public smsLandingService: SmsLandingService,
        private loggingService: LoggingService,
        private readonly route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.clientName$.unsubscribe();
        this.introductoryText$.unsubscribe();
        this.conclusion$.unsubscribe();
        this.notices$.unsubscribe();
        this.pageTitle$.unsubscribe();
    }

    errorHandler(event: any) {
        event.target.src = 'assets/img/code_logo.svg';
        (event.target as HTMLImageElement).style.width = '60%';
    }

    ngOnInit(): void {
        // Get survey code and pull the survey
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
            next: (params: Params) => {
                this.questionnaireIdService.setQuestionnaireId(
                    params['questionnaireId']
                );

                this.questionnaireService.fetchQuestionnaire(
                    this.questionnaireIdService.questionnaireId
                );

                // pull questionnaire
                this.questionnaireService.questionnaireSubject
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: questionnaire => {
                            if (questionnaire) {
                                // Questionnaire exists, pull status type
                                this.currentStatusType =
                                    questionnaire.status as StatusType;
                            }

                            // If not found, don't do anything and not found page will be displayed
                            if (
                                this.currentStatusType &&
                                this.currentStatusType !== StatusType.NotFound
                            ) {
                                // If survey has NOT been started, completed, or expired,
                                // load this page. Otherwise, we will redirect to the /s/CODE page
                                if (
                                    ![
                                        StatusType.Expired,
                                        StatusType.Submitted,
                                        StatusType.InProgress,
                                    ].includes(this.currentStatusType)
                                ) {
                                    // Survey is in a valid status i.e. pending to display this page
                                    this.brandingService.fetchBranding(
                                        this.questionnaireIdService
                                            .questionnaireId
                                    );

                                    // PUll branding info
                                    this.brandingService.brandingSubject
                                        .pipe(takeUntil(this.destroy$))
                                        .subscribe({
                                            next: (res: IBranding) => {
                                                if (res && res.orgName) {
                                                    this.clientName$.next(
                                                        `${res.orgName} logo`
                                                    );
                                                }

                                                if (res && res.languageCode) {
                                                    this.translate.use(
                                                        res.languageCode
                                                    );
                                                }
                                            },
                                            error: error => {
                                                const body: ILogData = {
                                                    userAgent:
                                                        window.navigator
                                                            .userAgent,
                                                    details: error,
                                                    submitTimestamp:
                                                        new Date().toJSON(),
                                                };

                                                this.loggingService.logError(
                                                    this.questionnaireIdService
                                                        .questionnaireId,
                                                    body
                                                );
                                            },
                                        });

                                    // Pull the HMTL for the page
                                    this.smsLandingService.fetchSmsLandingHtml(
                                        this.questionnaireIdService
                                            .questionnaireId
                                    );

                                    this.smsLandingService.smsLandingHtml$
                                        .pipe(takeUntil(this.destroy$))
                                        .subscribe({
                                            next: smsLandingHtml => {
                                                // HTML needs to be assigned to page.
                                                this.introductoryText$.next(
                                                    smsLandingHtml.introductoryText
                                                );
                                                this.conclusion$.next(
                                                    smsLandingHtml.conclusion
                                                );
                                                this.notices$.next(
                                                    smsLandingHtml.notices
                                                );
                                                this.pageTitle$.next(
                                                    smsLandingHtml.pageTitle
                                                );
                                            },
                                            error: error => {
                                                const body: ILogData = {
                                                    userAgent:
                                                        window.navigator
                                                            .userAgent,
                                                    details: error,
                                                    submitTimestamp:
                                                        new Date().toJSON(),
                                                };

                                                this.loggingService.logError(
                                                    this.questionnaireIdService
                                                        .questionnaireId,
                                                    body
                                                );
                                            },
                                        });
                                } else {
                                    // Survey is either expired, submitted or in progress.
                                    // Redirect to /s/code
                                    // Redirect to /s/ because survey is found and started or completed or expired
                                    this.router.navigate([
                                        `/s/${this.questionnaireIdService.questionnaireId}`,
                                    ]);
                                }
                            }
                        },
                        error: error => {
                            const body: ILogData = {
                                userAgent: window.navigator.userAgent,
                                details: error,
                                submitTimestamp: new Date().toJSON(),
                            };

                            this.loggingService.logError(
                                this.questionnaireIdService.questionnaireId,
                                body
                            );
                        },
                    });
            },
            error: error => {
                const body: ILogData = {
                    userAgent: window.navigator.userAgent,
                    details: error,
                    submitTimestamp: new Date().toJSON(),
                };

                this.loggingService.logError(
                    this.questionnaireIdService.questionnaireId,
                    body
                );
            },
        });
    }

    // Patient clicked or tapped the decline survey button
    declineSurvey() {
        this.smsLandingService
            .declineSurvey(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: data => {
                    // If successfully declined survey, route to declined page.
                    this.router.navigate([
                        `/m/${this.questionnaireIdService.questionnaireId}/decline`,
                    ]);
                },
                error: error => {
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );

                    const appendElement =
                        '<p class="error-message" style="text-align: center; font-size: 10px;color: red;">An error occured, please try again.</p>';
                    this.errorMessage = appendElement;
                },
            });
    }

    // Patient wants to begin survey, redirect to the update contact preferences
    // page.
    updateContactPreferences() {
        this.router.navigate([
            `/m/${this.questionnaireIdService.questionnaireId}/begin`,
        ]);
    }

    protected readonly NOT_FOUND_PAGE_CONTENT = NOT_FOUND_PAGE_CONTENT;
}
