import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvConfigurationService, IAppConfiguration } from '@core/service';
import { map, Observable, first } from 'rxjs';

export interface IApiResponse<T> {
    error: string;
    result: T;
}

@Injectable({ providedIn: 'root' })
export class RestApiService {
    private url = '';
    private client: HttpClient;

    constructor(
        private http: HttpClient,
        private envConfig: EnvConfigurationService
    ) {
        this.client = this.http;
        this.envConfig.configuration$
            .pipe(first())
            .subscribe((res: IAppConfiguration) => {
                this.url = res.apiUrl;
            });
    }

    public get<T>(path: string, options?: object): Observable<T> {
        return this.client
            .get<T>(`${this.url}${path}`, options)
            .pipe(map((res: any) => res as T));
    }

    public post<T>(
        path: string,
        body: object | string,
        options?: object
    ): Observable<T | null> {
        return this.client
            .post<T>(`${this.url}${path}`, body, options)
            .pipe(map((res: any) => res as T));
    }

    public put<T>(
        path: string,
        body?: object | string,
        options?: object
    ): Observable<T | null> {
        return this.client
            .put<T>(`${this.url}${path}`, body, options)
            .pipe(map((res: any) => res));
    }
}
