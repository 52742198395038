import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    IResearchQuestions,
    ISubmitResearchQuestion,
} from '@models/consent.model';
import { IDynamicComponent } from '@pages/dynamic-page/dynamic.component';
import { ConsentService } from '@services/consent.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { AsyncSubject } from 'rxjs/internal/AsyncSubject';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs';
import { LoggingService } from '@services/logging.service';
import { ILogData } from '@models/log.model';

@Component({
    selector: 'app-research-question',
    templateUrl: './research-question.component.html',
    providers: [ConsentService],
})
export class ResearchQuestionComponent
    implements IDynamicComponent, OnDestroy, OnInit
{
    @Input() screen: any;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();
    @ViewChild('autosize') autosize!: CdkTextareaAutosize;

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public researchQuestions$: AsyncSubject<IResearchQuestions> =
        new AsyncSubject<IResearchQuestions>();
    questionPlaceholder: string;
    public fontsizeTextAria: string = '14px';
    private startTimestamp: Date = new Date();

    form = new FormGroup({
        question: new FormControl(null),
        noQuestionAssign: new FormControl(true),
    });

    constructor(
        private _ngZone: NgZone,
        public questionnaireIdService: QuestionnaireIdService,
        private consentService: ConsentService,
        private loggingService: LoggingService
    ) {}

    ngOnInit(): void {
        this.consentService
            .getResearchQuestions(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: IResearchQuestions) => {
                    if (response) {
                        const markQuestionPLaceholder =
                            response?.questionPlaceholder;
                        this.questionPlaceholder = !!markQuestionPLaceholder
                            ? markQuestionPLaceholder
                            : 'Please enter ANY QUESTIONS you have about the study';

                        this.researchQuestions$.next(response);
                        this.researchQuestions$.complete();
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });

        this.form.controls.question.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                const noQuestionAssign = this.form.controls.noQuestionAssign;
                if (value) {
                    noQuestionAssign.patchValue(false, {
                        emitEvent: false,
                    });
                } else {
                    noQuestionAssign.patchValue(true, {
                        emitEvent: false,
                    });
                }
            });

        this.form.controls.noQuestionAssign.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                const question = this.form.controls.question;
                if (value) {
                    question.patchValue(null, { emitEvent: false });
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public handleNavigateForward(event: any) {
        this.navigateForward.emit(event);
    }

    public handleNavigateBack(event: any) {
        this.navigateBack.emit(event);
    }

    public onSubmit() {
        const body: ISubmitResearchQuestion = {
            questionnaireId: this.questionnaireIdService.questionnaireId,
            dontHaveQuestion: this.form.value.noQuestionAssign,
            question: this.form.value.question,
            startTimestamp: this.startTimestamp.toJSON(),
            submitTimestamp: new Date().toJSON(),
            userAgent: window.navigator.userAgent,
        };

        this.consentService
            .submitResearchQuestion(
                this.questionnaireIdService.questionnaireId,
                body
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('researchquestion');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    triggerResize() {
        this._ngZone.onStable
            .pipe(take(1))
            .subscribe(() => this.autosize.resizeToFitContent(true));
    }
}
