import { IAppColor } from '../models';
import tinycolor from 'tinycolor2';
import { DefaultColorList } from '@shared/constants/default-theme';

function computeColors(hex: string): IAppColor[] {
    return [
        getOpacityColorbject(hex, '50'),
        getColorObject(tinycolor(hex).lighten(37), '100'),
        getColorObject(tinycolor(hex).lighten(26), '200'),
        getColorObject(tinycolor(hex).lighten(12), '300'),
        getColorObject(tinycolor(hex).lighten(5), '400'),
        getColorObject(tinycolor(hex), '500'),
        getColorObject(tinycolor(hex).darken(6), '600'),
        getColorObject(tinycolor(hex).darken(8), '700'),
        getColorObject(tinycolor(hex).darken(18), '800'),
        getColorObject(tinycolor(hex).darken(24), '900'),
        getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
        getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
        getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
        getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
}

function getColorObject(value: any, name: string): IAppColor {
    const c = tinycolor(value);
    return {
        name: name,
        hex: c.toHexString(),
        darkContrast: c.isLight(),
    };
}

function getOpacityColorbject(value: any, name: string): IAppColor {
    const c = tinycolor(value);
    c.setAlpha(0.12);
    return {
        name: name,
        hex: c.toRgbString(),
        darkContrast: c.isLight(),
    };
}

export function savePrimaryColor(
    primaryColor: string,
    lightPrimary: string | null
): IAppColor[] {
    const primaryColorPalette = computeColors(primaryColor);

    for (const color of primaryColorPalette) {
        const key1 = `--theme-primary-${color.name}`;

        let value1 = null;
        if (lightPrimary && color.name === '50') {
            value1 = lightPrimary;
        } else {
            value1 = color.hex;
        }

        const key2 = `--theme-primary-contrast-${color.name}`;
        const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
        document.documentElement.style.setProperty(key1, value1);
        document.documentElement.style.setProperty(key2, value2);
    }
    return primaryColorPalette;
}

export function saveSecondaryColor(secondaryColor: string): IAppColor[] {
    const secondaryColorPalette = computeColors(secondaryColor);

    for (const color of secondaryColorPalette) {
        const key1 = `--theme-secondary-${color.name}`;
        const value1 = color.hex;
        const key2 = `--theme-secondary-contrast-${color.name}`;
        const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
        document.documentElement.style.setProperty(key1, value1);
        document.documentElement.style.setProperty(key2, value2);
    }
    return secondaryColorPalette;
}

export function saveErrorColor(): IAppColor[] {
    const errorColorPalette = computeColors(DefaultColorList.ErrorColor);

    for (const color of errorColorPalette) {
        const key1 = `--theme-error-${color.name}`;

        let value1 = null;
        if (color.name === '50') {
            value1 = DefaultColorList.ErrorContainerColor;
        } else if (color.name === '700') {
            value1 = DefaultColorList.OnErrorContainerColor;
        } else {
            value1 = color.hex;
        }

        const key2 = `--theme-error-contrast-${color.name}`;
        const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
        document.documentElement.style.setProperty(key1, value1);
        document.documentElement.style.setProperty(key2, value2);
    }
    return errorColorPalette;
}

export function changeTheme(
    primary: string,
    secondary: string,
    lightPrimary: string | null = null
) {
    savePrimaryColor(primary, lightPrimary);
    saveSecondaryColor(secondary);
    saveErrorColor();
}
