import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import {
    LoadingService,
    MetaDataService,
    QuestionnaireService,
} from '@services';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { BrandingService } from '@services/branding.service';
import { IBranding, ILogData } from '@models';
import { ContactInformationService } from '@services/contact-information.service';
import { NOT_FOUND_PAGE_CONTENT } from '../../../constants';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sms-decline',
    templateUrl: './sms-decline.component.html',
    styleUrls: ['../sms-landing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SmsDeclineComponent implements OnInit, OnDestroy {
    public clientName$: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Code Technology Logo'
    );
    private destroy$: Subject<boolean> = new Subject<boolean>();
    contactInformation: any;

    constructor(
        public loadingService: LoadingService,
        private readonly route: ActivatedRoute,
        public metaDataService: MetaDataService,
        private questionnaireIdService: QuestionnaireIdService,
        public questionnaireService: QuestionnaireService,
        public brandingService: BrandingService,
        public contactInformationService: ContactInformationService,
        private loggingService: LoggingService,
        public translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.clientName$.unsubscribe();
    }

    ngOnInit(): void {
        // pull branding information
        const getBrandingInfo = () => {
            this.brandingService.fetchBranding(
                this.questionnaireIdService.questionnaireId
            );

            this.brandingService.brandingSubject
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (res: IBranding) => {
                        if (res && res.orgName) {
                            this.clientName$.next(`${res.orgName} logo`);
                        }
                        if (res && res.languageCode) {
                            this.translate.use(res.languageCode);
                        }
                    },
                    error: error => {
                        const body: ILogData = {
                            userAgent: window.navigator.userAgent,
                            details: error,
                            submitTimestamp: new Date().toJSON(),
                        };

                        this.loggingService.logError(
                            this.questionnaireIdService.questionnaireId,
                            body
                        );
                    },
                });
        };

        // pull survey
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
            next: (params: Params) => {
                this.questionnaireIdService.setQuestionnaireId(
                    params['questionnaireId']
                );
                this.questionnaireService.fetchQuestionnaire(
                    this.questionnaireIdService.questionnaireId
                );

                this.questionnaireService.questionnaireSubject
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: questionnaire => {
                            // If questionnaire exists, pull contact information for this
                            // schedule and display in page.
                            if (questionnaire) {
                                // Survey is in a valid status i.e. pending to display this page
                                getBrandingInfo();

                                this.contactInformationService
                                    .fetchContactInformation(
                                        this.questionnaireIdService
                                            .questionnaireId
                                    )
                                    .subscribe({
                                        next: response => {
                                            this.contactInformation = response;
                                        },
                                        error: error => {
                                            const body: ILogData = {
                                                userAgent:
                                                    window.navigator.userAgent,
                                                details: error,
                                                submitTimestamp:
                                                    new Date().toJSON(),
                                            };

                                            this.loggingService.logError(
                                                questionnaire.id,
                                                body
                                            );
                                        },
                                    });

                                // Make put request to decline the survey
                            } else {
                                // Questionnaire doesn't exist, display not found message
                            }
                        },
                        error: error => {
                            const body: ILogData = {
                                userAgent: window.navigator.userAgent,
                                details: error,
                                submitTimestamp: new Date().toJSON(),
                            };

                            this.loggingService.logError(
                                this.questionnaireIdService.questionnaireId,
                                body
                            );
                        },
                    });
            },
            error: error => {
                const body: ILogData = {
                    userAgent: window.navigator.userAgent,
                    details: error,
                    submitTimestamp: new Date().toJSON(),
                };

                this.loggingService.logError(
                    this.questionnaireIdService.questionnaireId,
                    body
                );
            },
        });
    }

    errorHandler(event: any) {
        event.target.src = 'assets/img/code_logo.svg';
        (event.target as HTMLImageElement).style.width = '60%';
    }

    protected readonly NOT_FOUND_PAGE_CONTENT = NOT_FOUND_PAGE_CONTENT;
}
