import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ILogData, IScreen, ISubjectConsent, ISubmitConsent } from '@models';
import { IDynamicComponent } from '@pages/dynamic-page/dynamic.component';
import { ConsentService } from '@services/consent.service';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { Subject, AsyncSubject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-consent-subject',
    templateUrl: './consent-subject.component.html',
    providers: [ConsentService],
})
export class ConsentSubjectComponent
    implements IDynamicComponent, OnDestroy, OnInit
{
    @Input() screen: IScreen;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();

    form = new FormGroup({
        name: new FormControl(null),
        consent: new FormControl(null),
        signature: new FormControl(false),
    });

    consentOptions: { value: boolean; label: string }[];

    nameFormControlLabel: string;
    public fontsizeTextAria: string = '14px';
    private startTimestamp: Date = new Date();

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public consentSubject$: AsyncSubject<ISubjectConsent> =
        new AsyncSubject<ISubjectConsent>();

    constructor(
        public questionnaireIdService: QuestionnaireIdService,
        private consentService: ConsentService,
        private loggingService: LoggingService
    ) {}

    ngOnInit(): void {
        this.consentService
            .getSubjectConsent(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: ISubjectConsent) => {
                    if (response) {
                        const firstAndLastNamePlaceholder =
                            response?.firstAndLastNamePlaceholder;
                        this.nameFormControlLabel =
                            !!firstAndLastNamePlaceholder
                                ? firstAndLastNamePlaceholder
                                : 'Please enter your FIRST and LAST NAME';

                        this.consentOptions = [
                            {
                                value: true,
                                label: !!response?.consentLabel
                                    ? response.consentLabel
                                    : 'I consent',
                            },
                            {
                                value: false,
                                label: !!response?.dontConsentLabel
                                    ? response.dontConsentLabel
                                    : 'I do not consent',
                            },
                        ];

                        this.consentSubject$.next(response);
                        this.consentSubject$.complete();
                    }
                },
                error: error => {
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public handleNavigateForward(event: any) {
        this.navigateForward.emit(event);
    }

    public handleNavigateBack(event: any) {
        this.navigateBack.emit(event);
    }

    public onSubmit() {
        const body: ISubmitConsent = {
            questionnaireId: this.questionnaireIdService.questionnaireId,
            consent: this.form.value.consent,
            name: this.form.value.name,
            acceptAsSignature: this.form.value.signature,
            startTimestamp: this.startTimestamp.toJSON(),
            submitTimestamp: new Date().toJSON(),
            userAgent: window.navigator.userAgent,
        };

        this.consentService
            .submitConsent(this.questionnaireIdService.questionnaireId, body)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('subjectconsent');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    trackByFn(index: number) {
        return index;
    }
}
