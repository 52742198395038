<div id="shadow-wrapper" class="wrapper-shadow" (scroll)="showBoxShadow()">
    <div class="shadow shadow--bottom"></div>
    <div appFavIcon class="container">
        <header role="header">
            <mat-toolbar id="banner" role="banner" color="primary">
                <img
                    *ngIf="
                        (brandingService.brandingSubject | async)?.logoUrl;
                        else code_logo;
                        let logo
                    "
                    alt="{{ clientName$ | async }}"
                    aria-label="Company logo"
                    src="{{ logo }}"
                    (error)="errorHandler($event)"
                />
                <ng-template #code_logo>
                    <img
                        class="w-60"
                        aria-label="Code Technology logo"
                        alt="Code Technology logo"
                        src="assets/img/code_logo.svg"
                    />
                </ng-template>
            </mat-toolbar>
        </header>
        <main role="main">
            <ng-container [ngSwitch]="currentStatusType">
                <ng-container *ngSwitchCase="statusType.InProgress">
                    <router-outlet></router-outlet>
                </ng-container>
                <ng-container *ngSwitchCase="statusType.Pending">
                    <router-outlet></router-outlet>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <app-progress-bar
                        [status]="status$ | async"
                    ></app-progress-bar>
                    <div
                        *ngIf="
                            (questionnaireService.questionnaireSubject | async)
                                ?.statusMessage;
                            let statusMessage
                        "
                    >
                        <app-status-page
                            [statusText]="statusMessage"
                        ></app-status-page>
                    </div>
                </ng-container>
            </ng-container>
        </main>

        <footer role="contentinfo">
            <section
                role="region"
                [innerHtml]="(metaDataService.metaData$ | async)?.copyright"
                aria-label="Copyright CODE Technology"
            ></section>

            <a
                href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                title="Explanation of WCAG 2 Level AA conformance"
                target="_blank"
            >
                <img
                    aria-label="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
                    src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-blue-v"
                    alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
                />
            </a>
        </footer>
    </div>
</div>
