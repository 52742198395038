import { Injectable, OnDestroy } from '@angular/core';
import { DefaultColorList } from '@shared/constants';
import { IBranding, ILogData } from '@shared/models';
import { changeTheme } from '@shared/utility';
import { AsyncSubject, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';
import { LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root',
})
export class BrandingService implements OnDestroy {
    public brandingSubject = new AsyncSubject<IBranding>();

    constructor(
        private readonly restApi: RestApiService,
        private loggingService: LoggingService
    ) {}

    public ngOnDestroy(): void {
        this.brandingSubject.unsubscribe();
    }

    public fetchBranding(id: string): void {
        this.restApi
            .get<IBranding>(`${PATH.QUESTIONNAIRE}/${id}/${PATH.BRANDING}`)
            .pipe(take(1))
            .subscribe({
                next: (branding: IBranding) => {
                    if (
                        branding?.theme &&
                        branding?.theme?.primaryColor &&
                        branding?.theme?.secondaryColor
                    ) {
                        changeTheme(
                            branding.theme.primaryColor,
                            branding.theme.secondaryColor
                        );
                    }

                    this.brandingSubject.next(branding);
                    this.brandingSubject.complete();
                },
                error: error => {
                    changeTheme(
                        DefaultColorList.PrimaryColor,
                        DefaultColorList.SecondaryColor,
                        DefaultColorList.LightPrimaryColor
                    );

                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(id, body);
                },
            });
    }
}
