import {
    AfterContentChecked,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IBranding, ILogData } from '@models';
import { LoggingService } from '@services/logging.service';
import { StatusType } from '@shared/constants';
import {
    LoadingService,
    MetaDataService,
    QuestionnaireService,
} from '@shared/services';
import { BrandingService } from '@shared/services/branding.service';
import { QuestionnaireIdService } from '@shared/services/questionnaireId.service';
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public readonly statusType = StatusType;
    public currentStatusType: StatusType | null = null;
    public status$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public clientName$: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Code Technology Logo'
    );

    constructor(
        public loadingService: LoadingService,
        public metaDataService: MetaDataService,
        private questionnaireIdService: QuestionnaireIdService,
        public questionnaireService: QuestionnaireService,
        public brandingService: BrandingService,
        private readonly route: ActivatedRoute,
        private loggingService: LoggingService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('en');

        combineLatest([this.route.params, this.route.queryParams])
            .pipe(
                map(results => ({
                    params: results[0],
                    queryParams: results[1],
                }))
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: x => {
                    const params = x.params;
                    const queryParams = x.queryParams;
                    
                    this.questionnaireIdService.setQuestionnaireId(
                        params['questionnaireId']
                    );
                    

                    if(queryParams['phoneCaller']){
                        this.questionnaireIdService.setPhoneCaller(
                            queryParams['phoneCaller'],
                        );
                    }

                    this.questionnaireService.fetchQuestionnaire(
                        this.questionnaireIdService.questionnaireId
                    );

                    this.questionnaireService.questionnaireSubject
                        .pipe(takeUntil(this.destroy$))
                        .subscribe({
                            next: questionnaire => {
                                if (questionnaire) {
                                    this.currentStatusType =
                                        questionnaire.status as StatusType;

                                    if (
                                        this.currentStatusType &&
                                        this.currentStatusType !==
                                            StatusType.NotFound
                                    ) {
                                        this.brandingService.fetchBranding(
                                            this.questionnaireIdService
                                                .questionnaireId
                                        );

                                        this.brandingService.brandingSubject
                                            .pipe(takeUntil(this.destroy$))
                                            .subscribe({
                                                next: (res: IBranding) => {
                                                    if (res && res?.orgName) {
                                                        this.clientName$.next(
                                                            `${res.orgName} Logo`
                                                        );

                                                        if (res.languageCode) {
                                                            this.translate.use(
                                                                res.languageCode
                                                            );
                                                        }
                                                    }
                                                },
                                                error: error => {
                                                    const body: ILogData = {
                                                        userAgent:
                                                            window.navigator
                                                                .userAgent,
                                                        details: error,
                                                        submitTimestamp:
                                                            new Date().toJSON(),
                                                    };

                                                    this.loggingService.logError(
                                                        questionnaire.id,
                                                        body
                                                    );
                                                },
                                            });

                                        if (
                                            this.currentStatusType ===
                                            StatusType.Submitted
                                        ) {
                                            this.status$.next(100);
                                        }
                                    }
                                }
                            },
                            error: error => {
                                const body: ILogData = {
                                    userAgent: window.navigator.userAgent,
                                    details: error,
                                    submitTimestamp: new Date().toJSON(),
                                };

                                this.loggingService.logError(null, body);
                            },
                        });
                },
                error: error => {
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngAfterContentChecked() {
        this.showBoxShadow();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.status$.unsubscribe();
        this.clientName$.unsubscribe();
    }

    errorHandler(event: any) {
        event.target.src = 'assets/img/code_logo.svg';
        (event.target as HTMLImageElement).style.width = '60%';
    }

    showBoxShadow() {
        const scrollingElement = document.getElementById('shadow-wrapper');
        let scrollHeight: number = 0;
        let rightScrollPos: number = 0;
        const shadowBottom: HTMLElement | null =
            document.querySelector('.shadow--bottom');

        if (scrollingElement) {
            scrollHeight = scrollingElement.scrollHeight;
            rightScrollPos = scrollingElement.scrollTop;
            const contentScrollHeight = scrollHeight - window.innerHeight;
            const currentScroll = rightScrollPos / contentScrollHeight;

            if (shadowBottom) {
                isNaN(currentScroll)
                    ? (shadowBottom.style.opacity = '0')
                    : (shadowBottom.style.opacity = (
                          1 - currentScroll
                      ).toString());
            }
        }
    }
}
