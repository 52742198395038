<div appFavIcon class="container">
    <header role="header" class="border-bottom">
        <mat-toolbar id="banner" role="banner" color="primary">
            <img
                *ngIf="
                    (brandingService.brandingSubject | async)?.logoUrl;
                    else code_logo;
                    let logo
                "
                alt="{{ clientName$ | async }}"
                aria-label="Company logo"
                src="{{ logo }}"
                (error)="errorHandler($event)"
            />
            <ng-template #code_logo>
                <img
                    class="w-60"
                    aria-label="Code Technology logo"
                    alt="Code Technology logo"
                    src="../../../../assets/img/code_logo.svg"
                />
            </ng-template>
        </mat-toolbar>
    </header>
    <main role="main">
        <div class="mobile-landing-wrapper">
            <div
                *ngIf="
                    (brandingService.brandingSubject | async)?.logoUrl;
                    else not_found
                "
            >
                <ng-container>
                    <h1 class="centered-title">{{ pageTitle$ | async }}</h1>
                    <div [innerHTML]="introductoryText$ | async"></div>
                </ng-container>
                <ng-container>
                    <div class="inline-wrapper">
                        <app-button
                            type="filledPrimary"
                            (appClick)="updateContactPreferences()"
                        >
                            {{ 'proctor.sms.landing.begin' | translate }}
                        </app-button>
                    </div>
                </ng-container>
                <ng-container>
                    <div [innerHTML]="conclusion$ | async"></div>
                </ng-container>
                <ng-container>
                    <div [innerHTML]="errorMessage"></div>
                    <div class="inline-wrapper">
                        <app-button
                            type="outlinedPrimary"
                            (appClick)="declineSurvey()"
                        >
                            {{ 'proctor.sms.landing.decline' | translate }}
                        </app-button>
                    </div>
                </ng-container>
                <ng-container>
                    <div [innerHTML]="notices$ | async"></div>
                </ng-container>
            </div>

            <ng-template #not_found>
                <div
                    class="wrapper"
                    style="
                        min-height: calc(
                            100vh - 10.875rem - 1.25rem - 1.5rem - 0.25rem
                        );
                    "
                >
                    <div class="content-status-page">
                        <div
                            class="not-found-text"
                            [innerHTML]="NOT_FOUND_PAGE_CONTENT"
                        ></div>
                    </div>
                </div>
            </ng-template>
        </div>
    </main>
    <footer role="contentinfo">
        <section
            role="region"
            [innerHtml]="(metaDataService.metaData$ | async)?.copyright"
            aria-label="Copyright CODE Technology"
        ></section>

        <a
            href="https://www.w3.org/WAI/WCAG2AA-Conformance"
            title="Explanation of WCAG 2 Level AA conformance"
            target="_blank"
        >
            <img
                aria-label="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
                src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-blue-v"
                alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
            />
        </a>
    </footer>
    <app-spinner></app-spinner>
</div>
