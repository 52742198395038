import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { MockService } from '@services/mock.service';
import { EnvConfigurationService, IAppConfiguration } from '@core/service';
import { first, of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
    private isStubMode: boolean = false;
    constructor(
        private mockService: MockService,
        private envConfig: EnvConfigurationService
    ) {
        this.envConfig.configuration$
            .pipe(first())
            .subscribe((res: IAppConfiguration) => {
                this.isStubMode = res.isStubMode ?? res.isStubMode;
            });
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (this.isStubMode) {
            return of(
                new HttpResponse(
                    this.mockService.getMockData(
                        request.url,
                        request.method,
                        request.body
                    )
                )
            ).pipe(delay(100));
        }
        return next.handle(request);
    }
}
