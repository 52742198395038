<div class="wrapper">
    <div
        class="consent"
        *ngIf="researchQuestions$ | async; let researchQuestions"
    >
        <app-paragraph [text]="researchQuestions?.text"></app-paragraph>
        <form [formGroup]="form" class="consent-form">
            <mat-form-field
                class="research-question full-width"
                appearance="outline"
                [style.fontSize]="fontsizeTextAria"
            >
                <mat-label>
                    {{ questionPlaceholder }}
                </mat-label>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    formControlName="question"
                ></textarea>
            </mat-form-field>
            <section class="consent-form-checkbox">
                <mat-checkbox
                    role="checkbox"
                    color="primary"
                    aria-label="Accept or reject as a question mark"
                    formControlName="noQuestionAssign"
                >
                    {{ researchQuestions?.noQuestionLabel }}
                </mat-checkbox>
            </section>
        </form>
        <div class="inline-wrapper">
            <app-button
                type="filledPrimary"
                [icon_name]="'navigate_next'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="true"
                [isIconBeforeLabel]="false"
                (appClick)="onSubmit()"
            >
                Next
            </app-button>
            <app-button
                type="outlinedPrimary"
                [icon_name]="'navigate_before'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="false"
                [isIconBeforeLabel]="true"
                (appClick)="handleNavigateBack($event)"
            >
                Back
            </app-button>
        </div>
    </div>
</div>
