import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';
import {
    IResearchQuestions,
    IResearchSubjectInformationAndConsentForm,
    ISubjectConsent,
    ISubmitConsent,
    ISubmitResearchQuestion,
    ISubmitResearchSubjectInfo,
} from '@models/consent.model';

@Injectable({
    providedIn: 'root',
})
export class ConsentService {
    constructor(private readonly restApi: RestApiService) {}

    public getResearchSubjInfo(
        questionnaireId: string
    ): Observable<IResearchSubjectInformationAndConsentForm> {
        return this.restApi
            .get<IResearchSubjectInformationAndConsentForm>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.RESEARCH_SUBJECT_INFO}`
            )
            .pipe(take(1));
    }

    public getSubjectConsent(
        questionnaireId: string
    ): Observable<ISubjectConsent> {
        return this.restApi
            .get<ISubjectConsent>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SUBJECT_CONSENT}`
            )
            .pipe(take(1));
    }

    public getResearchQuestions(
        questionnaireId: string
    ): Observable<IResearchQuestions> {
        return this.restApi
            .get<IResearchQuestions>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.RESEARCH_QUESTION}`
            )
            .pipe(take(1));
    }

    public submitResearchSubjInfo(
        questionnaireId: string,
        body: ISubmitResearchSubjectInfo
    ): Observable<null> {
        return this.restApi.post(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.RESEARCH_SUBJECT_INFO}`,
            body
        );
    }

    public submitConsent(
        questionnaireId: string,
        body: ISubmitConsent
    ): Observable<null> {
        return this.restApi.post(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.CONSENT}`,
            body
        );
    }

    public submitResearchQuestion(
        questionnaireId: string,
        body: ISubmitResearchQuestion
    ): Observable<null> {
        return this.restApi.post(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.RESEARCH_QUESTION}`,
            body
        );
    }
}
