import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    LoadingService,
    MetaDataService,
    QuestionnaireService,
    RestApiService,
} from '@shared/services';
import { NgModule } from '@angular/core';
import { LoadingInterceptor } from './loading.interceptor';
import { MockInterceptor } from './mock.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { LoggingInterceptor } from './logging.interceptor';
import { QuestionnaireIdService } from '@shared/services/questionnaireId.service';
import { BrandingService } from '@shared/services/branding.service';
import { LoggingService } from '@services/logging.service';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
];
@NgModule({
    imports: [HttpClientModule],
    providers: [
        RestApiService,
        LoadingService,
        LoggingService,
        httpInterceptorProviders,
        MetaDataService,
        QuestionnaireIdService,
        QuestionnaireService,
        BrandingService,
    ],
})
export class HttpModule {}
