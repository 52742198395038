import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/loading-indicator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/material';
import { ProgressBarComponent } from './components/progress-bar';
import { ParagraphComponent } from './components/paragraph/paragraph.component';

@NgModule({
    declarations: [SpinnerComponent, ProgressBarComponent, ParagraphComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SpinnerComponent,
        ProgressBarComponent,
        ParagraphComponent,
    ],
})
export class SharedModule {}
