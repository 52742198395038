import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireIdService {
    private questionnaireId$ = new BehaviorSubject<string>('');
    private phoneCaller$ = new BehaviorSubject<string>('');

    public setQuestionnaireId(questionnaireId: string) {
        this.questionnaireId$.next(questionnaireId);
    }

    public get questionnaireId(): string {
        return this.questionnaireId$.value;
    }

    public setPhoneCaller(phoneCaller: string) {
        this.phoneCaller$.next(phoneCaller);
    }

    public get phoneCaller(): string {
        return this.phoneCaller$.value;
    }
}
