import { Injectable, OnDestroy } from '@angular/core';
import {
    IAnswer,
    IQuestion,
    IQuestionnaire,
    ISubmitQuestionnaire,
    IGratitude,
} from '@shared/models';
import { Observable, take, AsyncSubject } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireService implements OnDestroy {
    public questionnaireSubject = new AsyncSubject<IQuestionnaire>();

    constructor(private readonly restApi: RestApiService) {}

    public ngOnDestroy(): void {
        this.questionnaireSubject.unsubscribe();
    }

    public fetchQuestionnaire(questionnaireId: string): void {
        this.restApi
            .get<IQuestionnaire>(`${PATH.QUESTIONNAIRE}/${questionnaireId}`)
            .pipe(take(1))
            .subscribe(
                questionnaire => {
                    this.questionnaireSubject.next(questionnaire);
                    this.questionnaireSubject.complete();
                },
                error => {
                    this.questionnaireSubject.next(error);
                    this.questionnaireSubject.complete();
                }
            );
    }

    public changeStatusQuestionnaire(
        questionnaireId: string,
        body: ISubmitQuestionnaire
    ): Observable<null> {
        return this.restApi.put(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}`,
            body
        );
    }

    public submitQuestionnaire(
        questionnaireId: string,
        phoneCaller: string,
        body: ISubmitQuestionnaire
    ): Observable<null> {

        let apiPath = `${PATH.QUESTIONNAIRE}/${questionnaireId}`

        if(phoneCaller){
            apiPath = `${PATH.QUESTIONNAIRE}/${questionnaireId}?phoneCaller=${phoneCaller}`
        }
        return this.restApi.post(
            apiPath,
            body
        );
    }

    public getGratitude(questionnaireId: string): Observable<IGratitude> {
        return this.restApi
            .get<IGratitude>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.GRATITUDE}`
            )
            .pipe(take(1));
    }

    public getQuestionById(
        questionnaireId: string,
        questionId: string
    ): Observable<IQuestion> {
        return this.restApi
            .get<IQuestion>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.QUESTION}/${questionId}`
            )
            .pipe(take(1));
    }

    public submitAnswerbyQuestion(
        questionnaireId: string,
        questionId: string,
        body: IAnswer
    ): Observable<IAnswer | null> {
        return this.restApi
            .post<IAnswer>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.QUESTION}/${questionId}/${PATH.ANSWER}`,
                body
            )
            .pipe(take(1));
    }

    public getAnswerQuestionById(
        questionnaireId: string,
        questionId: string
    ): Observable<IAnswer> {
        return this.restApi
            .get<IAnswer>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.QUESTION}/${questionId}/${PATH.ANSWER}`
            )
            .pipe(take(1));
    }
}
