import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    LoadingService,
    MetaDataService,
    QuestionnaireService,
} from '@services';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { BrandingService } from '@services/branding.service';
import { IBranding, ILogData } from '@models';
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { confirmEmailValidation } from '@shared/utility/form-control.function';
import { NOT_FOUND_PAGE_CONTENT, StatusType } from '../../constants';
import { SmsLandingService } from '@services/sms-landing.service';
import { LoggingService } from '@services/logging.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-sms-update-contacts',
    templateUrl: './update-contact-preferences.component.html',
    styleUrls: ['./sms-landing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UpdateContactPreferencesComponent implements OnInit, OnDestroy {
    public clientName$: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Code Technology Logo'
    );
    private destroy$: Subject<boolean> = new Subject<boolean>();
    matcher = new MyErrorStateMatcher();
    email: string = '';
    public currentStatusType: StatusType | null = null;
    public errorMessage: string = '';

    constructor(
        public loadingService: LoadingService,
        private readonly route: ActivatedRoute,
        public metaDataService: MetaDataService,
        private questionnaireIdService: QuestionnaireIdService,
        public questionnaireService: QuestionnaireService,
        public brandingService: BrandingService,
        public smsLandingService: SmsLandingService,
        private loggingService: LoggingService,
        private router: Router,
        public translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.clientName$.unsubscribe();
    }

    ngOnInit(): void {
        // PUll branding information for the survey
        const getBrandingInfo = () => {
            this.brandingService.fetchBranding(
                this.questionnaireIdService.questionnaireId
            );

            this.brandingService.brandingSubject
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (res: IBranding) => {
                        if (res && res.orgName) {
                            this.clientName$.next(`${res.orgName} logo`);
                        }

                        if (res && res.languageCode) {
                            this.translate.use(res.languageCode);
                        }
                    },
                    error: error => {
                        const body: ILogData = {
                            userAgent: window.navigator.userAgent,
                            details: error,
                            submitTimestamp: new Date().toJSON(),
                        };

                        this.loggingService.logError(
                            this.questionnaireIdService.questionnaireId,
                            body
                        );
                    },
                });
        };

        this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
            next: (params: Params) => {
                this.questionnaireIdService.setQuestionnaireId(
                    params['questionnaireId']
                );
                // Pull questionnaire
                this.questionnaireService.fetchQuestionnaire(
                    this.questionnaireIdService.questionnaireId
                );

                this.questionnaireService.questionnaireSubject
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: questionnaire => {
                            if (questionnaire) {
                                // Questionnaire exists, pull the status
                                this.currentStatusType =
                                    questionnaire.status as StatusType;

                                // If status of survey is not found or not started, display page.
                                if (
                                    [
                                        StatusType.Pending,
                                        StatusType.NotFound,
                                    ].includes(this.currentStatusType)
                                ) {
                                    // Survey is in a valid status i.e. pending to display this page
                                    getBrandingInfo();
                                } else {
                                    // Redirect to /s/ because survey is found and started or completed or expired
                                    this.router.navigate([
                                        `/s/${this.questionnaireIdService.questionnaireId}`,
                                    ]);
                                }
                            }
                            // else Questionnaire doesn't exist, display not found message
                        },
                        error: error => {
                            const body: ILogData = {
                                userAgent: window.navigator.userAgent,
                                details: error,
                                submitTimestamp: new Date().toJSON(),
                            };

                            this.loggingService.logError(
                                this.questionnaireIdService.questionnaireId,
                                body
                            );
                        },
                    });
            },
            error: error => {
                const body: ILogData = {
                    userAgent: window.navigator.userAgent,
                    details: error,
                    submitTimestamp: new Date().toJSON(),
                };

                this.loggingService.logError(
                    this.questionnaireIdService.questionnaireId,
                    body
                );
            },
        });
    }

    errorHandler(event: any) {
        event.target.src = 'assets/img/code_logo.svg';
        (event.target as HTMLImageElement).style.width = '60%';
    }

    // Form controls. Confirming email is valid and emails match.
    reactiveForm = new FormGroup({
        emailAddress: new FormControl('', [
            Validators.email,
            Validators.maxLength(100),
        ]),
        confirmEmailAddress: new FormControl('', [
            Validators.email,
            confirmEmailValidation('emailAddress'),
            Validators.maxLength(100),
        ]),
    });
    protected readonly NOT_FOUND_PAGE_CONTENT = NOT_FOUND_PAGE_CONTENT;

    // Submit survey button is clicked
    public onSubmit() {
        // If the form is ready to submit with no errors, make POST to update contact info.
        if (this.reactiveForm.valid) {
            // Submit updated contact preference
            this.smsLandingService
                .updateContactPreferences(
                    this.questionnaireIdService.questionnaireId,
                    this.email
                )
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: data => {
                        if (data?.success) {
                            this.router.navigate([
                                `/s/${this.questionnaireIdService.questionnaireId}`,
                            ]);
                        }
                    },
                    error: error => {
                        const body: ILogData = {
                            userAgent: window.navigator.userAgent,
                            details: error,
                            submitTimestamp: new Date().toJSON(),
                        };

                        this.loggingService.logError(
                            this.questionnaireIdService.questionnaireId,
                            body
                        );

                        const appendElement =
                            '<p class="error-message" style="text-align: center; font-size: 10px;color: red;">An error occured, please try again.</p>';
                        this.errorMessage = appendElement;
                    },
                });
        }
    }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}
