import {
    Component,
    Input,
    Output,
    OnDestroy,
    OnInit,
    EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    IBattery,
    IQuestion,
    IQuestionnaire,
    AnswerBase,
    TextareaAnswer,
    IScreen,
    IElementList,
    IAnswerValues,
    IAnswerValidation,
    IAnswer,
    TextboxAnswer,
    RangeAnswer,
    ILogData,
} from '@models';
import { ListAnswer } from '@models/answer-list';
import { IDynamicComponent } from '@pages/dynamic-page';
import { QuestionnaireService } from '@services';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import {
    AnswerType,
    ANSWER_NAME_MAP,
    FieldType,
} from '@shared/constants';
import { Subject, takeUntil, Observable, map, of } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
})
export class QuestionComponent implements IDynamicComponent, OnInit, OnDestroy {
    @Input() screen: IScreen;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();

    protected destroy$: Subject<boolean> = new Subject<boolean>();
    public copyright$!: Observable<string | null | undefined>;
    public question$: Subject<IQuestion> = new Subject<IQuestion>();
    public convertedAnswerSetting$!: Observable<AnswerBase<any>[]>;
    public eq5dCompliant: boolean = false;

    public nextQuestionText: string = 'Start';
    public previousQuestionText: string = 'Back';

    constructor(
        public questionnaireService: QuestionnaireService,
        public questionnaireIdService: QuestionnaireIdService,
        public loggingService: LoggingService,
        public readonly router: Router,
        protected translate: TranslateService
    ) {}

    private isAvaliableComment(list: IElementList[]): boolean {
        return list ? list.some(el => el.validation.commentAvailable) : false;
    }

    public handleNavigateForward(event: any) {
        this.navigateForward.emit(event);
    }

    public handleNavigateBack(event: any) {
        this.navigateBack.emit(event);
    }

    ngOnInit(): void {
        this.translate.get('proctor.navigation.next').subscribe(text => {
            this.nextQuestionText = text;
        });

        this.translate.get('proctor.navigation.back').subscribe(text => {
            this.previousQuestionText = text;
        });

        this.questionnaireService
            .getAnswerQuestionById(
                this.questionnaireIdService.questionnaireId,
                this.screen.dataId
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (answer: IAnswer) => {
                    this.loadData(
                        this.questionnaireIdService.questionnaireId,
                        this.screen.dataId,
                        answer
                    );
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    protected loadData(
        questionnaireId: string,
        questionId: string,
        prevAnswer: IAnswer | null = null
    ) {
        this.questionnaireService
            .getQuestionById(questionnaireId, questionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (res: IQuestion) => {
                    this.question$.next(res);

                    if (res) {
                        this.transformAnswer(
                            res.answerSettings?.values,
                            res.answerSettings?.validation,
                            prevAnswer
                        );

                        this.setCopyright(res.batteryId);
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    protected setCopyright(batteryId: string) {
        this.copyright$ = this.questionnaireService.questionnaireSubject.pipe(
            takeUntil(this.destroy$),
            map(
                (questionnaire: IQuestionnaire) =>
                    questionnaire.batteries &&
                    questionnaire.batteries.find(
                        (battery: IBattery) => battery.id === batteryId
                    )?.copyright
            )
        );
    }

    private isMandatoryComment(
        currentList: IElementList[],
        prevList: IAnswer | null
    ): boolean {
        return (
            currentList
                ?.map((element: IElementList) => ({
                    req: prevList?.values?.some(
                        el =>
                            el.id === element.id &&
                            element?.validation?.commentAvailable &&
                            element?.validation?.commentMandatory
                    )
                        ? true
                        : false,
                }))
                .filter(el => el.req).length > 0
        );
    }

    protected transformAnswer(
        values: IAnswerValues,
        validation: IAnswerValidation,
        prevAnswer: IAnswer | null
    ) {
        let convertedAnswerValues: AnswerBase<string>[] = [];
        let prevValue: string = '';

        if (values) {
            switch (ANSWER_NAME_MAP[values.type as AnswerType]) {
                case FieldType.List:
                    if (!validation?.multipleAnswersAvailable) {
                        if (prevAnswer?.values) {
                            prevValue = prevAnswer?.values[0]?.id || '';
                        }
                    }

                    if (values.list?.length > 0) {
                        convertedAnswerValues = [
                            new ListAnswer({
                                key: values.type,
                                options: values?.list.map(
                                    (element: IElementList) => ({
                                        key: element.id,
                                        value: element?.displayText
                                            .split(' (')
                                            .join('<br/>('),
                                        isCommentAvailable:
                                            !!element?.validation
                                                ?.commentAvailable,
                                        isCommentMandatory:
                                            !!element?.validation
                                                ?.commentMandatory,
                                        checked: prevAnswer?.values?.find(
                                            el => el.id === element.id
                                        )
                                            ? true
                                            : false,
                                    })
                                ),
                                isMultiselect:
                                    !!validation?.multipleAnswersAvailable,
                                required: !!validation?.mandatoryAnswer,
                                value: prevValue,
                                order: 1,
                            }),
                        ];
                    }

                    if (
                        validation?.commentAvailable ||
                        this.isAvaliableComment(values?.list)
                    ) {
                        let placeholderText: string;
                        this.translate
                            .get('proctor.survey.textarea.placeholder')
                            .subscribe(text => {
                                placeholderText = text;
                            });

                        convertedAnswerValues.push(
                            new TextareaAnswer({
                                key: 'comment',
                                disabled: !validation?.commentAvailable,
                                required:
                                    !!validation?.commentMandatory ||
                                    this.isMandatoryComment(
                                        values?.list,
                                        prevAnswer
                                    ),
                                value: prevAnswer?.comment,
                                placeholder: placeholderText,
                                order: 2,
                            })
                        );
                    }
                    break;

                case FieldType.Textarea:
                    let placeholderText: string;
                    this.translate
                        .get('proctor.survey.textboxanswer.placeholder', {
                            value1: values.value.validation.minValue,
                            value2: values.value.validation.maxValue,
                        })
                        .subscribe(text => {
                            placeholderText = text;
                        });
                    convertedAnswerValues = [
                        new TextboxAnswer({
                            key: values.type,
                            required: !!validation?.mandatoryAnswer,
                            value: prevAnswer?.values[0]?.value,
                            max: values?.value?.validation?.maxValue,
                            min: values?.value?.validation?.minValue,
                            placeholder: placeholderText,
                            order: 1,
                        }),
                    ];
                    break;
                case FieldType.Range:
                    convertedAnswerValues = [
                        new RangeAnswer({
                            key: values.type,
                            required: !!validation?.mandatoryAnswer,
                            type: values.type,
                            value: prevAnswer?.values[0]?.value,
                            max: values?.range?.maxValue,
                            min: values?.range?.minValue,
                            order: 1,
                            rangeOptions: {
                                vertical: values?.range?.vertical,
                                minValueLabel: values?.range?.minValueLabel,
                                maxValueLabel: values?.range?.maxValueLabel,
                                notApplicableOption: values?.range?.notApplicableOption,
                                notApplicableAnswerKey: values?.range?.notApplicableAnswerKey,
                                valueStep: values?.range?.valueStep,
                                showValuesStep: values?.range?.showValuesStep,
                                smallTicksStep: values?.range?.smallTicksStep,
                                mediumTicksStep: values?.range?.mediumTicksStep,
                                largeTicksStep: values?.range?.largeTicksStep,
                                tickLocation: values?.range?.tickLocation,
                                showTicks: values?.range?.showTicks,
                                showTip: values?.range?.showTip,
                                showCurrentValue:
                                    values?.range?.showCurrentValue,
                                showCurrentValueText:
                                    values?.range?.showCurrentValueText,
                                eq5dCompliant: values?.range?.eq5dCompliant,
                            },
                        }),
                    ];

                    this.eq5dCompliant = !!values?.range?.eq5dCompliant;
                    break;
                default:
                    break;
            }
        }
        this.convertedAnswerSetting$ = of(convertedAnswerValues);
    }

    protected handleSubmit(answer: IAnswer) {
        this.questionnaireService
            .submitAnswerbyQuestion(
                this.questionnaireIdService.questionnaireId,
                this.screen.dataId,
                answer
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('Question');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.question$.unsubscribe();
    }
}
