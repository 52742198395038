<button
    role="button"
    cdkFocusInitial
    mat-button
    class="btn"
    type="button"
    [disabled]="disabled"
    (click)="handleClick($event)"
    [ngClass]="[
        buttonTypeClass,
        disabled ? 'disabled' : '',
        size,
        isIconBeforeLabel ? 'label_before' : '',
        isIconAfterLabel ? 'label_after' : ''
    ]"
>
    <div *ngIf="icon_name !== ''">
        <mat-icon
            [ngClass]="{
                'white_icon': isWhiteIcon,
                'material-icons-outlined': isOutlined
            }"
        >
            {{ icon_name }}
        </mat-icon>
    </div>
    <ng-content></ng-content>
</button>
