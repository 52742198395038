import { Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ILogData, ISubmitQuestionnaire } from '@models';
import { StatusType } from '@shared/constants';
import { IAnswer } from '@models/answer.model';
import { QuestionComponent } from '../question';

@Component({
    selector: 'app-submit',
    templateUrl: './submit.component.html',
})
export class SubmitComponent extends QuestionComponent {
    public submitSurveyText: string;
    override ngOnInit(): void {
        this.loadData(
            this.questionnaireIdService.questionnaireId,
            this.screen.dataId
        );
        this.translate.get('proctor.navigation.submit').subscribe(text => {
            this.submitSurveyText = text;
        });
        this.translate.get('proctor.navigation.back').subscribe(text => {
            this.previousQuestionText = text;
        });
    }

    protected override handleSubmit(answer: IAnswer) {
        const body: ISubmitQuestionnaire = {
            id: this.questionnaireIdService.questionnaireId,
            status: StatusType.Submitted,
            userAgent: window.navigator.userAgent,
        };
        this.questionnaireService
            .submitAnswerbyQuestion(
                this.questionnaireIdService.questionnaireId,
                this.screen.id,
                answer
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.questionnaireService
                        .submitQuestionnaire(
                            this.questionnaireIdService.questionnaireId,
                            this.questionnaireIdService.phoneCaller,
                            body
                        )
                        .pipe(takeUntil(this.destroy$))
                        .subscribe({
                            next: () => {
                                this.handleNavigateForward('Submit');
                            },
                            error: error => {
                                const body: ILogData = {
                                    screenId: this.screen?.id
                                        ? this.screen.id
                                        : null,
                                    userAgent: window.navigator.userAgent,
                                    details: error,
                                    submitTimestamp: new Date().toJSON(),
                                };

                                this.loggingService.logError(
                                    this.questionnaireIdService.questionnaireId,
                                    body
                                );
                            },
                        });
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }
}
