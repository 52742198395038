import { Injectable, OnDestroy } from '@angular/core';
import { IScreen } from '@shared/models/screen.model';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class ScreenService implements OnDestroy {
    private screenSubject = new BehaviorSubject<IScreen | null>(null);
    public screen$ = this.screenSubject.asObservable();

    private screenIdSubject = new BehaviorSubject<string | null>(null);
    public screenId$ = this.screenIdSubject.asObservable();

    constructor(private readonly restApi: RestApiService) {}

    public getScreenById(
        questionnaireId: string,
        screenId: string
    ): Observable<IScreen> {
        return this.restApi
            .get<IScreen>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SCREEN}/${screenId}`
            )
            .pipe(take(1));
    }

    public getCurrentScreen(questionnaireId: string): Observable<IScreen> {
        return this.restApi
            .get<IScreen>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SCREEN}`
            )
            .pipe(take(1));
    }

    ngOnDestroy(): void {
        this.screenSubject.next(null);
        this.screenSubject.unsubscribe();

        this.screenIdSubject.next(null);
        this.screenIdSubject.unsubscribe();
    }
}
