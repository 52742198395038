<div appFavIcon class="container">
    <header>
        <mat-toolbar role="banner" color="primary">
            <ng-template #header_logo>
                <img
                    *ngIf="
                        (brandingService.brandingSubject | async)?.logoUrl;
                        else code_logo;
                        let logo
                    "
                    alt="{{ clientName$ | async }}"
                    aria-label="Company logo"
                    src="{{ logo }}"
                    (error)="errorHandler($event)"
                />
                <ng-template #code_logo>
                    <img
                        class="w-60"
                        aria-label="Code Technology logo"
                        alt="Code Technology logo"
                        src="assets/img/code_logo.svg"
                    />
                </ng-template>
            </ng-template>
            <a
                *ngIf="
                    faqLogoRedirectLink$ | async;
                    else no_redirect_link;
                    let href
                "
                [href]="href"
                target="_blank"
            >
                <ng-container *ngTemplateOutlet="header_logo"></ng-container>
            </a>
            <ng-template #no_redirect_link>
                <ng-container *ngTemplateOutlet="header_logo"></ng-container>
            </ng-template>
        </mat-toolbar>
    </header>

    <main role="main">
        <div class="wrapper">
            <ng-container>
                <div
                    *ngIf="
                        (brandingService.brandingSubject | async)?.logoUrl && validFaq === true;
                        else noSurvey
                    "
                    id="faq-body"
                    [innerHTML]="content"
                ></div>
                <ng-template #noSurvey>
                    <div
                        class="wrapper"
                        style="
                            min-height: calc(
                                100vh - 10.875rem - 1.25rem - 1.5rem - 0.25rem
                            );
                        "
                    >
                        <div class="content-status-page">
                            <div class="paragraph" [innerHTML]="content"></div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </main>

    <footer role="contentinfo">
        <section
            role="region"
            [innerHtml]="(metaDataService.metaData$ | async)?.copyright"
            aria-label="Copyright CODE Technology"
        ></section>

        <a
            href="https://www.w3.org/WAI/WCAG2AA-Conformance"
            title="Explanation of WCAG 2 Level AA conformance"
            target="_blank"
        >
            <img
                aria-label="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
                src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-blue-v"
                alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
            />
        </a>
    </footer>
</div>
