import { Injectable, OnDestroy } from '@angular/core';
import { RestApiService } from '@services/rest-api.service';
import { BehaviorSubject, take } from 'rxjs';
import { IFaq } from '@models/faq.model';
import { PATH } from '../../config/path.configs';
import { ILogData } from '@models';
import { LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root',
})
export class FaqService implements OnDestroy {
    private faqSubject = new BehaviorSubject<IFaq>({
        success: true,
        body: '',
    } as IFaq);
    public faq$ = this.faqSubject.asObservable();

    constructor(
        private readonly restApi: RestApiService,
        private loggingService: LoggingService
    ) {}

    ngOnDestroy(): void {
        this.faqSubject.complete();
        this.faqSubject.unsubscribe();
    }

    public fetchFaq(questionnaireId: string): void {
        this.restApi
            .get<IFaq>(`${PATH.QUESTIONNAIRE}/${questionnaireId}/faq`)
            .pipe(take(1))
            .subscribe(
                faq => this.faqSubject.next(faq),
                error => {
                    this.faqSubject.next(error);
                    const body: ILogData = {
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(questionnaireId, body);
                }
            );
    }
}
