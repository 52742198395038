import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { IContactInformation } from '@models/contact-information.model';
import { RestApiService } from '@services/rest-api.service';
import { ISmsLandingHtml } from '@models/sms-landing-html.model';
import { PATH } from '../../config/path.configs';

@Injectable({
    providedIn: 'root',
})
export class ContactInformationService implements OnDestroy {
    private contactInformationSubject =
        new BehaviorSubject<IContactInformation>({} as IContactInformation);
    public contactInformation$ = this.contactInformationSubject.asObservable();
    constructor(private readonly restApi: RestApiService) {}

    ngOnDestroy(): void {
        this.contactInformationSubject.complete();
        this.contactInformationSubject.unsubscribe();
    }

    public fetchContactInformation(
        questionnaireId: string
    ): Observable<IContactInformation> {
        return this.restApi.get<IContactInformation>(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.CONTACT_INFORMATION}`
        );
    }
}
