import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@pages/home';
import { FaqComponent } from '@pages/faq/faq.component';
import { SmsLandingComponent } from '@pages/sms-landing/sms-landing.component';
import { UpdateContactPreferencesComponent } from '@pages/sms-landing/update-contact-preferences.component';
import { SmsDeclineComponent } from '@pages/sms-landing/sms-decline/sms-decline.component';

const routes: Routes = [
    {
        path: 'p/:questionnaireId/faq',
        component: FaqComponent,
    },
    {
        path: 's/:questionnaireId',
        component: HomeComponent,
        children: [
            {
                path: window['routingImproved'] ? 's' : '',
                loadChildren: () =>
                    import(
                        'src/app/shared/pages/dynamic-page/dynamic-page.module'
                    ).then(m => m.DynamicPageModule),
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'cat/:questionnaireId',
        component: HomeComponent,
        children: [
            {
                path: window['routingImproved'] ? 'cat' : '',
                loadChildren: () =>
                    import(
                        'src/app/shared/pages/dynamic-page/dynamic-page.module'
                    ).then(m => m.DynamicPageModule),
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'm/:questionnaireId',
        component: SmsLandingComponent,
    },
    {
        path: 'm/:questionnaireId/begin',
        component: UpdateContactPreferencesComponent,
    },
    {
        path: 'm/:questionnaireId/decline',
        component: SmsDeclineComponent,
    },
    {
        path: '**',
        loadChildren: () =>
            import('src/app/shared/pages/not-found/not-found.module').then(
                m => m.NotFoundModule
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
