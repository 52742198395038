import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { IAnswer, IQuestionnaire } from '@models';
import { RestApiService } from '@services/rest-api.service';
import { PATH } from '../../config/path.configs';
import { ISmsLandingHtml } from '@models/sms-landing-html.model';
import { assertSuccessfulReferenceEmit } from '@angular/compiler-cli/src/ngtsc/imports';

@Injectable({
    providedIn: 'root',
})
export class SmsLandingService implements OnDestroy {
    private smsLandingHtmlSubject = new BehaviorSubject<ISmsLandingHtml>(
        {} as ISmsLandingHtml
    );
    public smsLandingHtml$ = this.smsLandingHtmlSubject.asObservable();
    constructor(private readonly restApi: RestApiService) {}

    ngOnDestroy(): void {
        this.smsLandingHtmlSubject.complete();
        this.smsLandingHtmlSubject.unsubscribe();
    }

    public fetchSmsLandingHtml(questionnaireId: string): void {
        this.restApi
            .get<ISmsLandingHtml>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.SMS_LANDING_PAGE}`
            )
            .pipe(take(1))
            .subscribe(
                smsLandingHtml =>
                    this.smsLandingHtmlSubject.next(smsLandingHtml),
                error => this.smsLandingHtmlSubject.next(error)
            );
    }

    public declineSurvey(
        questionnaireId: string
    ): Observable<DeclineSurveySuccess | null> {
        return this.restApi
            .post<DeclineSurveySuccess>(
                `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.DECLINE_SURVEY}`,
                { id: questionnaireId }
            )
            .pipe(take(1));
    }

    public updateContactPreferences(
        questionnaireId: string,
        email: string
    ): Observable<DeclineSurveySuccess | null> {
        return this.restApi
            .post<DeclineSurveySuccess>(
                `${PATH.QUESTIONNAIRE}/${PATH.UPDATE_CONTACT_PREFERENCE}`,
                { code: questionnaireId, email: email }
            )
            .pipe(take(1));
    }
}

interface DeclineSurveySuccess {
    success: boolean;
    status: string;
    statusMessage: string;
}
