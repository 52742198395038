<div [style.display]="networkStatus ? 'block' : 'none'">
    <ng-container>
        <router-outlet></router-outlet>
        <app-spinner></app-spinner>
    </ng-container>
</div>

<ng-container *ngIf="!networkStatus">
    <div class="container">
        <header role="header">
            <mat-toolbar id="banner" role="banner" color="primary">
                <svg
                    aria-label="Code Technology logo"
                    alt="Code Technology logo"
                    class="w-60"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 4756 431.27"
                    style="enable-background: new 0 0 4756 431.27"
                    xml:space="preserve"
                >
                    <style type="text/css">
                        .st0 {
                            fill: #444545;
                        }
                        .st1 {
                            fill: #ffffff;
                        }
                    </style>
                    <g>
                        <path
                            class="st0"
                            d="M38,213.01c0-20.62,3.72-40.99,11.18-61.14c7.45-20.14,18.32-38.14,32.59-54.01
		c14.27-15.86,31.71-28.71,52.34-38.54c20.61-9.83,44.08-14.75,70.42-14.75c31.08,0,57.96,6.83,80.65,20.46
		c22.67,13.64,39.57,31.4,50.67,53.29l-50.44,34.73c-4.44-9.52-9.84-17.52-16.17-24.03c-6.35-6.5-13.17-11.66-20.46-15.46
		c-7.3-3.81-14.91-6.58-22.83-8.33c-7.93-1.74-15.71-2.62-23.32-2.62c-16.5,0-30.85,3.33-43.06,9.99
		c-12.21,6.66-22.36,15.31-30.45,25.93c-8.09,10.63-14.2,22.68-18.32,36.16c-4.12,13.49-6.18,26.88-6.18,40.2
		c0,14.91,2.38,29.26,7.14,43.06c4.76,13.8,11.49,25.93,20.21,36.4c8.72,10.46,19.19,18.87,31.4,25.21
		c12.21,6.35,25.62,9.52,40.21,9.52c7.61,0,15.53-0.95,23.78-2.85c8.25-1.91,16.1-4.91,23.56-9.04c7.45-4.12,14.34-9.43,20.7-15.94
		c6.34-6.5,11.41-14.35,15.22-23.55l53.76,30.92c-5.4,12.69-13.09,23.87-23.08,33.55c-9.99,9.68-21.26,17.84-33.78,24.5
		c-12.53,6.66-25.93,11.74-40.2,15.23s-28.24,5.23-41.87,5.23c-24.11,0-46.15-5.07-66.14-15.22
		c-19.98-10.15-37.19-23.39-51.62-39.73c-14.44-16.34-25.7-34.89-33.78-55.67C42.04,255.77,38,234.59,38,213.01z"
                        />
                        <path
                            class="st0"
                            d="M536.08,386.69c-24.74,0-47.19-4.83-67.32-14.51c-20.15-9.67-37.44-22.52-51.87-38.54
		c-14.43-16.01-25.62-34.33-33.54-54.95c-7.93-20.62-11.9-41.71-11.9-63.28c0-22.52,4.2-44.08,12.61-64.71
		c8.4-20.62,19.98-38.85,34.73-54.72c14.75-15.86,32.27-28.47,52.57-37.83c20.3-9.35,42.35-14.03,66.14-14.03
		c24.42,0,46.7,5,66.85,14.99c20.14,9.99,37.35,23.08,51.63,39.26c14.28,16.18,25.37,34.58,33.31,55.19
		c7.92,20.62,11.89,41.56,11.89,62.81c0,22.53-4.12,44.01-12.37,64.47c-8.25,20.46-19.67,38.54-34.26,54.24
		c-14.59,15.7-32.04,28.24-52.33,37.59C581.91,382.01,559.87,386.69,536.08,386.69z M438.07,215.39c0,14.59,2.22,28.71,6.65,42.35
		c4.44,13.65,10.86,25.7,19.27,36.16c8.4,10.47,18.71,18.88,30.93,25.22c12.21,6.35,26.08,9.52,41.63,9.52
		c15.86,0,29.89-3.25,42.11-9.75c12.21-6.5,22.52-15.15,30.93-25.93c8.4-10.78,14.75-22.91,19.03-36.4
		c4.28-13.48,6.42-27.19,6.42-41.16c0-14.59-2.3-28.7-6.9-42.35c-4.6-13.63-11.11-25.69-19.51-36.16
		c-8.41-10.46-18.72-18.79-30.93-24.98c-12.21-6.18-25.93-9.28-41.16-9.28c-15.86,0-29.97,3.26-42.34,9.75
		c-12.37,6.5-22.68,15.07-30.93,25.69c-8.25,10.63-14.51,22.68-18.79,36.16C440.21,187.72,438.07,201.44,438.07,215.39z"
                        />
                        <path
                            class="st0"
                            d="M746.05,384.31V46.49H868.8c27.6,0,51.78,4.44,72.56,13.32c20.77,8.88,38.14,20.93,52.1,36.16
		c13.96,15.22,24.42,33.07,31.4,53.53c6.97,20.46,10.46,42.27,10.46,65.42c0,25.69-3.89,48.93-11.65,69.71
		c-7.78,20.78-18.96,38.54-33.55,53.29c-14.59,14.75-32.11,26.17-52.57,34.26c-20.46,8.09-43.38,12.13-68.75,12.13H746.05z
		 M969.2,214.92c0-16.18-2.22-31.08-6.66-44.73c-4.45-13.64-10.94-25.37-19.51-35.21c-8.57-9.83-19.11-17.44-31.64-22.84
		c-12.53-5.39-26.72-8.09-42.58-8.09h-57.09v222.68h57.09c16.18,0,30.53-2.85,43.07-8.56c12.53-5.71,22.99-13.56,31.4-23.55
		c8.4-9.99,14.82-21.8,19.27-35.45C966.98,245.53,969.2,230.79,969.2,214.92z"
                        />
                        <path
                            class="st0"
                            d="M1314.77,326.73v57.57H1080.2V46.49h230.29v57.57h-164.63v81.37h142.27v53.29h-142.27v88.02H1314.77z"
                        />
                        <path
                            class="st0"
                            d="M1737.59,104.06h-108.01v280.25h-65.19V104.06h-108V46.49h281.2V104.06z"
                        />
                        <path
                            class="st0"
                            d="M2018.21,326.73v57.57h-234.57V46.49h230.29v57.57H1849.3v81.37h142.27v53.29H1849.3v88.02H2018.21z"
                        />
                        <path
                            class="st0"
                            d="M2050.28,213.01c0-20.62,3.72-40.99,11.18-61.14c7.45-20.14,18.32-38.14,32.59-54.01
		c14.27-15.86,31.71-28.71,52.34-38.54c20.61-9.83,44.08-14.75,70.42-14.75c31.08,0,57.96,6.83,80.65,20.46
		c22.67,13.64,39.57,31.4,50.67,53.29l-50.44,34.73c-4.44-9.52-9.84-17.52-16.17-24.03c-6.35-6.5-13.17-11.66-20.46-15.46
		c-7.3-3.81-14.91-6.58-22.83-8.33c-7.93-1.74-15.71-2.62-23.32-2.62c-16.5,0-30.85,3.33-43.06,9.99
		c-12.21,6.66-22.36,15.31-30.46,25.93c-8.09,10.63-14.2,22.68-18.32,36.16c-4.12,13.49-6.18,26.88-6.18,40.2
		c0,14.91,2.38,29.26,7.14,43.06c4.76,13.8,11.49,25.93,20.21,36.4c8.72,10.46,19.19,18.87,31.4,25.21
		c12.21,6.35,25.62,9.52,40.21,9.52c7.61,0,15.53-0.95,23.78-2.85s16.1-4.91,23.56-9.04c7.45-4.12,14.34-9.43,20.7-15.94
		c6.34-6.5,11.41-14.35,15.22-23.55l53.76,30.92c-5.4,12.69-13.09,23.87-23.08,33.55c-9.99,9.68-21.26,17.84-33.78,24.5
		c-12.53,6.66-25.93,11.74-40.2,15.23c-14.28,3.49-28.24,5.23-41.87,5.23c-24.11,0-46.15-5.07-66.14-15.22
		c-19.98-10.15-37.19-23.39-51.62-39.73c-14.44-16.34-25.7-34.89-33.78-55.67C2054.33,255.77,2050.28,234.59,2050.28,213.01z"
                        />
                        <path
                            class="st0"
                            d="M2688.03,46.49v337.82h-65.19V240.14h-153.21v144.17h-65.66V46.49h65.66v136.56h153.21V46.49H2688.03z"
                        />
                        <path
                            class="st0"
                            d="M2814.66,167.82v216.49H2749V46.49h50.91l176.04,221.72V46.96h65.67v337.35h-53.29L2814.66,167.82z"
                        />
                        <path
                            class="st0"
                            d="M3256.32,386.69c-24.74,0-47.19-4.83-67.32-14.51c-20.15-9.67-37.44-22.52-51.87-38.54
		c-14.43-16.01-25.62-34.33-33.54-54.95c-7.93-20.62-11.9-41.71-11.9-63.28c0-22.52,4.2-44.08,12.61-64.71
		c8.4-20.62,19.98-38.85,34.73-54.72c14.75-15.86,32.27-28.47,52.57-37.83c20.3-9.35,42.35-14.03,66.14-14.03
		c24.42,0,46.7,5,66.85,14.99c20.14,9.99,37.35,23.08,51.63,39.26c14.27,16.18,25.37,34.58,33.31,55.19
		c7.92,20.62,11.89,41.56,11.89,62.81c0,22.53-4.12,44.01-12.37,64.47c-8.25,20.46-19.67,38.54-34.26,54.24
		c-14.59,15.7-32.04,28.24-52.33,37.59C3302.15,382.01,3280.11,386.69,3256.32,386.69z M3158.3,215.39
		c0,14.59,2.22,28.71,6.65,42.35c4.44,13.65,10.86,25.7,19.27,36.16c8.4,10.47,18.71,18.88,30.93,25.22
		c12.21,6.35,26.08,9.52,41.63,9.52c15.86,0,29.89-3.25,42.11-9.75c12.21-6.5,22.52-15.15,30.93-25.93
		c8.4-10.78,14.75-22.91,19.03-36.4c4.28-13.48,6.42-27.19,6.42-41.16c0-14.59-2.3-28.7-6.9-42.35
		c-4.6-13.63-11.11-25.69-19.51-36.16c-8.41-10.46-18.72-18.79-30.93-24.98c-12.21-6.18-25.93-9.28-41.16-9.28
		c-15.86,0-29.97,3.26-42.34,9.75c-12.37,6.5-22.68,15.07-30.93,25.69c-8.25,10.63-14.51,22.68-18.79,36.16
		C3160.44,187.72,3158.3,201.44,3158.3,215.39z"
                        />
                        <path
                            class="st0"
                            d="M3466.29,384.31V46.49h65.66v280.25h172.24v57.57H3466.29z"
                        />
                        <path
                            class="st0"
                            d="M3886.06,386.69c-24.74,0-47.19-4.83-67.32-14.51c-20.15-9.67-37.44-22.52-51.87-38.54
		c-14.43-16.01-25.62-34.33-33.54-54.95c-7.93-20.62-11.9-41.71-11.9-63.28c0-22.52,4.2-44.08,12.61-64.71
		c8.4-20.62,19.98-38.85,34.73-54.72c14.75-15.86,32.27-28.47,52.57-37.83c20.3-9.35,42.35-14.03,66.14-14.03
		c24.42,0,46.7,5,66.85,14.99c20.14,9.99,37.35,23.08,51.63,39.26c14.27,16.18,25.37,34.58,33.31,55.19
		c7.92,20.62,11.89,41.56,11.89,62.81c0,22.53-4.12,44.01-12.37,64.47c-8.25,20.46-19.67,38.54-34.26,54.24
		c-14.59,15.7-32.04,28.24-52.33,37.59C3931.89,382.01,3909.85,386.69,3886.06,386.69z M3788.05,215.39
		c0,14.59,2.22,28.71,6.65,42.35c4.44,13.65,10.86,25.7,19.27,36.16c8.4,10.47,18.71,18.88,30.93,25.22
		c12.21,6.35,26.08,9.52,41.63,9.52c15.86,0,29.89-3.25,42.11-9.75c12.21-6.5,22.52-15.15,30.93-25.93
		c8.4-10.78,14.75-22.91,19.03-36.4c4.28-13.48,6.42-27.19,6.42-41.16c0-14.59-2.3-28.7-6.9-42.35
		c-4.6-13.63-11.11-25.69-19.51-36.16c-8.41-10.46-18.72-18.79-30.93-24.98c-12.21-6.18-25.93-9.28-41.16-9.28
		c-15.86,0-29.97,3.26-42.34,9.75c-12.37,6.5-22.68,15.07-30.93,25.69c-8.25,10.63-14.51,22.68-18.79,36.16
		C3790.19,187.72,3788.05,201.44,3788.05,215.39z"
                        />
                        <path
                            class="st0"
                            d="M4344.98,346.24c-26.97,26.96-58.38,40.44-94.21,40.44c-22.21,0-43.07-4.44-62.57-13.32
		c-19.51-8.88-36.48-21.09-50.91-36.64c-14.44-15.54-25.85-33.86-34.26-54.96c-8.4-21.09-12.61-43.85-12.61-68.28
		c0-23.15,4.21-44.96,12.61-65.42c8.4-20.46,19.98-38.38,34.73-53.77c14.75-15.38,32.12-27.6,52.1-36.64
		c19.99-9.04,41.71-13.56,65.19-13.56c31.72,0,58.92,6.66,81.61,19.98c22.67,13.32,39.73,31.25,51.14,53.77l-49.01,36.16
		c-8.57-16.81-20.54-29.66-35.92-38.54c-15.4-8.88-32.12-13.32-50.2-13.32c-14.27,0-27.35,3.02-39.25,9.04
		c-11.9,6.03-22.05,14.27-30.46,24.74c-8.4,10.46-14.91,22.52-19.51,36.16c-4.6,13.64-6.9,28.07-6.9,43.29
		c0,15.87,2.53,30.69,7.61,44.49c5.07,13.8,12.05,25.77,20.93,35.92c8.88,10.15,19.43,18.16,31.64,24.03
		c12.21,5.87,25.46,8.8,39.73,8.8c32.67,0,62.17-15.38,88.5-46.15v-23.79h-68.05v-48.06h122.29v173.67h-54.24V346.24z"
                        />
                        <path
                            class="st0"
                            d="M4479.14,46.49l83.27,162.72l84.69-162.72H4718L4595.24,266.3v118h-65.19V265.36L4407.78,46.49H4479.14z"
                        />
                    </g>
                </svg>
            </mat-toolbar>
        </header>
        <main role="main">
            <app-progress-bar [status]="0"></app-progress-bar>
            <app-status-page
                [statusText]="OFFLINE_STATUS_MESSAGE"
            ></app-status-page>
        </main>

        <footer role="contentinfo">
            <section
                role="region"
                [innerHtml]="copyright"
                aria-label="Copyright CODE Technology"
            ></section>

            <svg
                alt="WCAG 2.1"
                aria-label="WCAG 2.1"
                width="57"
                height="20"
                viewBox="0 0 57 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_238_438)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 0H56.5V20H0.5V0Z"
                        fill="#D8E5F0"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.735451 0H19.8264V20H0.735451V0Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 19.3549H56.5V20H0.5V19.3549Z"
                        fill="#727271"
                    />
                    <mask
                        id="mask0_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="19"
                        width="57"
                        height="1"
                    >
                        <path
                            d="M0.5 19.3549H56.5V20H0.5V19.3549Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 3.05176e-05H56.5V20H0.5V3.05176e-05Z"
                            fill="#6A6C6E"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.735451 3.05176e-05H19.8264V20H0.735451V3.05176e-05Z"
                            fill="#727271"
                        />
                    </g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M55.8636 0H56.5V19.3548H55.8636V0Z"
                        fill="#727271"
                    />
                    <mask
                        id="mask1_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="55"
                        y="0"
                        width="2"
                        height="20"
                    >
                        <path
                            d="M55.8636 0H56.5V19.3548H55.8636V0Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 0H56.5V20H0.5V0Z"
                            fill="#6A6C6E"
                        />
                    </g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 0H1.13636V20H0.5V0Z"
                        fill="#DADADA"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.87454 18.7097H55.9655V19.3548H1.87454V18.7097Z"
                        fill="#C8C8C8"
                    />
                    <mask
                        id="mask2_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="18"
                        width="55"
                        height="2"
                    >
                        <path
                            d="M1.87455 18.7097H55.9655V19.3548H1.87455V18.7097Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask2_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 -3.05176e-05H56.5V20H0.5V-3.05176e-05Z"
                            fill="#A6AFBC"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.735451 -3.05176e-05H19.8264V20H0.735451V-3.05176e-05Z"
                            fill="#C8C8C8"
                        />
                    </g>
                    <mask
                        id="mask3_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="18"
                        width="55"
                        height="2"
                    >
                        <path
                            d="M1.87455 18.7097H55.9655V19.3548H1.87455V18.7097Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask3_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.8636 -3.05176e-05H56.5V19.3548H55.8636V-3.05176e-05Z"
                            fill="#5C5C5B"
                        />
                    </g>
                    <mask
                        id="mask4_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="55"
                        y="18"
                        width="1"
                        height="2"
                    >
                        <path
                            d="M55.9655 18.7097H55.8636V19.3548H55.9655V18.7097Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask4_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 -3.05176e-05H56.5V20H0.5V-3.05176e-05Z"
                            fill="#57585A"
                        />
                    </g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M55.2018 0H55.8382V18.7097H55.2018V0Z"
                        fill="#C8C8C8"
                    />
                    <mask
                        id="mask5_238_438"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="55"
                        y="0"
                        width="1"
                        height="19"
                    >
                        <path
                            d="M55.2018 0H55.8382V18.7097H55.2018V0Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask5_238_438)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 0H56.5V20H0.5V0Z"
                            fill="#A6AFBC"
                        />
                    </g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 0H56.5V0.645161H0.5V0Z"
                        fill="#DADADA"
                    />
                    <path
                        d="M17.4973 6.34195L17.6691 7.40002L17.0327 8.58066C16.8813 8.2752 16.6637 8.00835 16.3964 7.80002C16.2785 7.69629 16.1378 7.6228 15.9861 7.58575C15.8344 7.54869 15.676 7.54918 15.5245 7.58712C15.2534 7.69011 15.01 7.85655 14.8143 8.07293C14.6185 8.2893 14.476 8.54948 14.3982 8.83228C14.2236 9.30484 14.1331 9.80508 14.1309 10.3097C14.1171 10.5997 14.1406 10.8904 14.2009 11.1742C13.9507 10.6404 13.8245 10.0555 13.8318 9.46455C13.8143 8.76247 13.9672 8.06677 14.2773 7.43873C14.3737 7.20183 14.5245 6.99165 14.7171 6.8256C14.9096 6.65955 15.1384 6.5424 15.3846 6.48388C15.5596 6.4742 15.7348 6.5013 15.899 6.56347C16.0633 6.62564 16.2131 6.72154 16.3391 6.84517C16.5409 7.05383 16.7124 7.29064 16.8482 7.54842L17.4464 6.33551L17.4973 6.34195Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M17.5736 12.3291C17.4434 12.5549 17.2966 12.7706 17.1346 12.9742C16.9031 13.2575 16.6173 13.4902 16.2945 13.6581C16.0085 13.8074 15.6841 13.8637 15.3654 13.8194C15.0683 13.7753 14.7864 13.6579 14.5445 13.4775C14.3202 13.3048 14.1191 13.1031 13.9464 12.8774C13.7745 12.6323 13.5073 12.142 13.5073 12.142C13.5073 12.142 13.66 12.6323 13.7491 12.8452C13.8743 13.1291 14.0279 13.3993 14.2073 13.6516C14.5024 14.1113 14.9564 14.4427 15.48 14.5807C15.8979 14.6563 16.3285 14.5662 16.6827 14.3291C16.8592 14.2035 17.0233 14.0609 17.1727 13.9032C17.3137 13.7466 17.4414 13.5783 17.5545 13.4C17.6118 13.3097 17.7073 13.1161 17.7073 13.1161L17.5545 12.2968L17.5736 12.3291Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M6.38637 6.38708L7.85 11.4322L9.31364 6.38708H10.3764L7.95182 14.6774H7.85L6.33546 9.54192L4.82091 14.6774H4.71909L2.29455 6.38708H3.35728L4.82091 11.4322L5.81364 8.03225L5.32364 6.38708H6.38637Z"
                        fill="#0B5196"
                    />
                    <path
                        d="M13.3864 12.0194C13.4087 12.7016 13.1998 13.371 12.7945 13.9161C12.6357 14.1226 12.4371 14.2942 12.2109 14.4205C11.9847 14.5469 11.7356 14.6254 11.4786 14.6514C11.2216 14.6773 10.962 14.6501 10.7157 14.5714C10.4694 14.4927 10.2414 14.3642 10.0455 14.1935C9.67916 13.8636 9.4104 13.437 9.26909 12.9613L10.0964 12.6129C10.1967 12.8943 10.3601 13.1483 10.5736 13.3548C10.7645 13.5321 11.0148 13.629 11.2736 13.6258C11.4253 13.618 11.5721 13.5689 11.6986 13.4838C11.8251 13.3986 11.9267 13.2806 11.9927 13.1419C12.1952 12.7924 12.2966 12.3922 12.2854 11.9871C12.3105 11.5777 12.2009 11.1716 11.9736 10.8323C11.8355 10.6655 11.66 10.5346 11.4619 10.4504C11.2638 10.3663 11.0487 10.3313 10.8345 10.3484H10.4336V9.85806L11.8464 7.38064H10.1409L9.66363 8.19999H9.35819V6.38708H13.2973V6.89031L11.8018 9.47096C12.2985 9.6202 12.7226 9.95134 12.9918 10.4C13.2678 10.8812 13.4066 11.4306 13.3927 11.9871L13.3864 12.0194Z"
                        fill="#0B5196"
                    />
                    <path
                        d="M33.2027 9.50324H32.0636L31.4273 6.99355C31.4018 6.90323 31.3615 6.71829 31.3064 6.43872C31.2512 6.15915 31.2194 5.97204 31.2109 5.87741C31.2109 5.99354 31.1791 6.18279 31.1154 6.44516C31.0518 6.70752 31.0115 6.89248 30.9946 7.00001L30.3582 9.50324H29.2255L28.0227 4.72259H29.0027L29.6073 7.30323C29.7133 7.78496 29.7897 8.20216 29.8364 8.55485C29.8364 8.43012 29.8661 8.23871 29.9254 7.98065C29.97 7.72258 30.0145 7.51613 30.0527 7.37419L30.74 4.69678H31.6818L32.3691 7.37419C32.3988 7.49462 32.437 7.67742 32.4836 7.92258C32.5303 8.16775 32.5643 8.3828 32.5855 8.56775C32.6067 8.3957 32.6406 8.18065 32.6873 7.92258C32.7339 7.66452 32.7764 7.44947 32.8145 7.27742L33.4127 4.69678H34.3991L33.2027 9.50324Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M37.6191 9.50325L37.2755 8.36776H35.5573L35.2136 9.50325H34.1382L35.7991 4.70325H37.0209L38.6882 9.50325H37.6191ZM37.04 7.51614L36.5054 5.76776C36.4673 5.63228 36.4355 5.52905 36.4227 5.44518C36.3506 5.72475 36.1385 6.41507 35.7864 7.51614H37.04Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M41.52 9.50324H39.3755V8.92904L39.9482 8.65808V5.56775L39.3755 5.29678V4.7226H41.52V5.29678L40.9473 5.56775V8.65808L41.52 8.92904V9.50324Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M42.5446 7.92261V7.0968H44.2755V7.91615L42.5446 7.92261Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M47.96 9.50324L47.5909 8.38711H45.8727L45.5291 9.5226H44.4536L46.1145 4.7226H47.3364L49.0036 9.5226L47.96 9.50324ZM47.3809 7.51613L46.8464 5.76775C46.8082 5.63227 46.7764 5.52904 46.7636 5.44517C46.6915 5.72474 46.4794 6.41506 46.1273 7.51613H47.3809Z"
                        fill="#144072"
                    />
                    <path
                        d="M52.79 9.50325L52.4464 8.36776H50.7282L50.3846 9.50325H49.3091L50.97 4.70325H52.1918L53.8591 9.50325H52.79ZM52.2109 7.51614L51.6764 5.76776C51.6382 5.63228 51.6064 5.52905 51.5936 5.44518C51.5215 5.72475 51.3094 6.41507 50.9573 7.51614H52.2109Z"
                        fill="#144072"
                    />
                    <path
                        d="M30.5873 17.0065H29.4927L28.8564 14.5871C28.8564 14.5032 28.7927 14.3226 28.7418 14.0516C28.6909 13.7807 28.6527 13.6 28.6464 13.5097C28.6464 13.6215 28.6145 13.8043 28.5509 14.0581C28.4873 14.3119 28.4491 14.4903 28.4364 14.5936L27.8 17.0065H26.7055L25.5473 12.4H26.4955L27.0746 14.9161C27.1764 15.3807 27.2506 15.7828 27.2973 16.1226C27.2973 16.0022 27.3248 15.8172 27.38 15.5678C27.4351 15.3183 27.4776 15.1226 27.5073 14.9807L28.1436 12.4H29.1364L29.7727 14.9807C29.7727 15.0968 29.8364 15.271 29.8809 15.5097C29.9255 15.7484 29.9573 15.9484 29.9827 16.1226C29.9827 15.9592 30.0167 15.7549 30.0845 15.5097C30.1524 15.2645 30.1949 15.0667 30.2118 14.9161L30.7909 12.4H31.7391L30.5873 17.0065Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M33.9537 13.1484C33.7908 13.1409 33.6287 13.1748 33.482 13.2469C33.3354 13.319 33.2087 13.427 33.1136 13.5613C32.8964 13.9046 32.7917 14.3088 32.8146 14.7161C32.8146 15.7441 33.1943 16.258 33.9537 16.258C34.3504 16.2417 34.7418 16.1589 35.1118 16.0129V16.8322C34.7079 16.9983 34.2749 17.0795 33.8391 17.0709C33.5582 17.0893 33.2767 17.0445 33.0149 16.9396C32.753 16.8348 32.5173 16.6725 32.3246 16.4645C31.9439 15.9666 31.756 15.3447 31.7964 14.7161C31.7831 14.2713 31.8724 13.8294 32.0573 13.4258C32.2176 13.0807 32.4771 12.7928 32.8018 12.6C33.1465 12.4009 33.538 12.3006 33.9346 12.3096C34.3962 12.3141 34.8509 12.4244 35.2646 12.6322L34.9527 13.4258C34.7848 13.3455 34.6127 13.2744 34.4373 13.2129C34.2805 13.1655 34.1172 13.1437 33.9537 13.1484Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M38.7709 17.0065L38.44 15.9097H36.7727L36.4418 17.0065H35.4045L37.0082 12.3807H38.1855L39.7955 17.0065H38.7709ZM38.2109 15.071L37.6955 13.3871C37.6573 13.2581 37.6318 13.1549 37.6127 13.0774C37.5449 13.3484 37.3497 14.0129 37.0273 15.071H38.2109Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M41.7873 14.4194H43.5882V16.8064C43.3186 16.8986 43.0419 16.9676 42.7609 17.0129C42.4996 17.0517 42.2359 17.0711 41.9718 17.071C41.6817 17.0905 41.3907 17.0458 41.1193 16.94C40.8479 16.8341 40.6025 16.6697 40.4 16.4581C40.0118 15.9586 39.8192 15.3314 39.8591 14.6968C39.8412 14.3776 39.8885 14.0582 39.998 13.7585C40.1075 13.4588 40.2769 13.1852 40.4954 12.9548C40.729 12.735 41.0036 12.5647 41.303 12.4539C41.6025 12.3431 41.9207 12.294 42.2391 12.3097C42.7032 12.3093 43.1625 12.406 43.5882 12.5935L43.27 13.3742C42.9486 13.2088 42.5932 13.1225 42.2327 13.1226C42.0445 13.1137 41.8566 13.1472 41.6828 13.2209C41.5089 13.2945 41.3533 13.4064 41.2273 13.5484C40.9643 13.8673 40.8302 14.2752 40.8518 14.6903C40.8253 15.0968 40.9328 15.5008 41.1573 15.8387C41.2622 15.9704 41.3967 16.0748 41.5494 16.1432C41.7021 16.2116 41.8687 16.2422 42.0354 16.2323C42.2492 16.233 42.4624 16.2114 42.6718 16.1677V15.2064H41.8127L41.7873 14.4194Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M49.8182 17.0065H46.77V16.3613L47.8391 15.1936L48.2082 14.7871C48.3094 14.6732 48.4029 14.5524 48.4882 14.4258C48.5644 14.3137 48.6264 14.1923 48.6727 14.0645C48.7164 13.9381 48.7379 13.8049 48.7364 13.671C48.7401 13.6041 48.7296 13.5372 48.7054 13.4748C48.6812 13.4125 48.644 13.3561 48.5964 13.3097C48.4941 13.2205 48.362 13.1743 48.2273 13.1807C48.0627 13.181 47.9008 13.2232 47.7564 13.3032C47.584 13.3994 47.4237 13.5164 47.2791 13.6516L46.7573 13.0065C46.8527 12.9226 46.9482 12.8387 47.05 12.7613C47.2688 12.5919 47.5203 12.4711 47.7882 12.4065C47.9507 12.3666 48.1174 12.3471 48.2845 12.3484C48.4833 12.3453 48.6812 12.3758 48.87 12.4387C49.0342 12.4932 49.1858 12.581 49.3155 12.6968C49.4373 12.8096 49.533 12.9484 49.5955 13.1032C49.6615 13.2737 49.6939 13.4556 49.6909 13.6387C49.6906 13.8153 49.6582 13.9902 49.5955 14.1549C49.5296 14.3257 49.4442 14.4881 49.3409 14.6387C49.2265 14.8043 49.1011 14.9616 48.9655 15.1097C48.8255 15.2645 48.6749 15.4237 48.5136 15.5871L47.9664 16.142V16.1871H49.8182V17.0065Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M50.7727 16.5548C50.7709 16.4688 50.786 16.3832 50.8173 16.3032C50.8412 16.2386 50.8806 16.181 50.9318 16.1355C50.9823 16.0936 51.0408 16.0629 51.1036 16.0452C51.1754 16.0351 51.2482 16.0351 51.32 16.0452C51.3896 16.0352 51.4603 16.0352 51.53 16.0452C51.5928 16.0629 51.6513 16.0936 51.7018 16.1355C51.755 16.1806 51.7965 16.2382 51.8227 16.3032C51.854 16.3832 51.8691 16.4688 51.8673 16.5548C51.8695 16.6388 51.8543 16.7223 51.8227 16.8C51.7956 16.8646 51.7542 16.9219 51.7018 16.9677C51.652 17.012 51.5934 17.0451 51.53 17.0645C51.4604 17.0748 51.3896 17.0748 51.32 17.0645C51.2482 17.0748 51.1754 17.0748 51.1036 17.0645C51.0402 17.0451 50.9816 17.012 50.9318 16.9677C50.8813 16.9216 50.8421 16.8641 50.8173 16.8C50.7868 16.722 50.7717 16.6387 50.7727 16.5548Z"
                        fill="#1D1D1B"
                    />
                    <path
                        d="M53.8654 17.0065H52.9045V14.342C52.9045 14.2904 52.9045 14.2194 52.9045 14.1484C52.9045 14.0774 52.9045 13.9936 52.9045 13.9097V13.4258L52.8536 13.4839L52.7709 13.5678L52.6754 13.6581L52.58 13.742L52.0455 14.1936L51.5809 13.6065L53.0446 12.4258H53.8336L53.8654 17.0065Z"
                        fill="#1D1D1B"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_238_438">
                        <rect
                            width="56"
                            height="20"
                            fill="white"
                            transform="translate(0.5)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </footer>
    </div>
</ng-container>
