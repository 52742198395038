import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BrandingService } from '@services/branding.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { FaqService } from '@services/faq.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { IBranding, ILogData } from '@models';
import { LoadingService, MetaDataService } from '@services';
import { NOT_FOUND_PAGE_CONTENT } from '@shared/constants';
import { Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FaqComponent implements OnInit, OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public clientName$: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Code Technology Logo'
    );
    public faqLogoRedirectLink$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    public color: string = '';
    content: SafeHtml | undefined;
    validFaq: boolean = true;

    constructor(
        public loadingService: LoadingService,
        private questionnaireIdService: QuestionnaireIdService,
        public brandingService: BrandingService,
        public metaDataService: MetaDataService,
        public faqService: FaqService,
        private readonly route: ActivatedRoute,
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer,
        private loggingService: LoggingService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.clientName$.unsubscribe();
        this.faqLogoRedirectLink$.unsubscribe();
    }

    ngOnInit(): void {
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
            next: (params: Params) => {
                // Pull questionnaireId and set value
                this.questionnaireIdService.setQuestionnaireId(
                    params['questionnaireId']
                );

                // Fetch the FAQ for the questionnaire ID
                this.faqService.fetchFaq(
                    this.questionnaireIdService.questionnaireId
                );

                this.faqService.faq$.pipe(takeUntil(this.destroy$)).subscribe({
                    next: faq => {
                        if (faq.success) {
                            this.validFaq = true;
                            // If success:
                            // 1. pull branding info for survey
                            // 2. determine if header image requires redirect link
                            // 3. adjust anchor tags in the body of FAQ to link properly
                            // 4. Set client name
                            this.brandingService.fetchBranding(
                                this.questionnaireIdService.questionnaireId
                            );

                            this.faqLogoRedirectLink$.next(
                                faq.faqLogoRedirectLink
                            );

                            // The anchor tags in the faq body that link to sections of the faq
                            // need to be adjusted to properly to have correct href url.
                            this.content = this.convertAnchorTags(faq.body);

                            this.brandingService.brandingSubject
                                .pipe(takeUntil(this.destroy$))
                                .subscribe({
                                    next: (res: IBranding) => {
                                        if (res && res.orgName) {
                                            this.clientName$.next(
                                                `${res.orgName} logo`
                                            );
                                        }
                                    },
                                    error: error => {
                                        const body: ILogData = {
                                            userAgent:
                                                window.navigator.userAgent,
                                            details: error,
                                            submitTimestamp:
                                                new Date().toJSON(),
                                        };

                                        this.loggingService.logError(
                                            this.questionnaireIdService
                                                .questionnaireId,
                                            body
                                        );
                                    },
                                });
                        } else {
                            // FAQ does not exist, display not found content
                            this.validFaq = false;
                            if (faq.body) {
                                this.content = faq.body;
                            } else {
                                this.content = NOT_FOUND_PAGE_CONTENT;
                            }
                        }
                    },
                    error: error => {
                        const body: ILogData = {
                            userAgent: window.navigator.userAgent,
                            details: error,
                            submitTimestamp: new Date().toJSON(),
                        };

                        this.loggingService.logError(
                            this.questionnaireIdService.questionnaireId,
                            body
                        );
                    },
                });
            },
            error: error => {
                const body: ILogData = {
                    userAgent: window.navigator.userAgent,
                    details: error,
                    submitTimestamp: new Date().toJSON(),
                };

                this.loggingService.logError(
                    this.questionnaireIdService.questionnaireId,
                    body
                );
            },
        });
    }

    errorHandler(event: any) {
        event.target.src = 'assets/img/code_logo.svg';
        (event.target as HTMLImageElement).style.width = '60%';
    }

    // https://stackoverflow.com/questions/56199389/how-to-make-anchor-link-with-id-work-as-expected-in-angular
    // Method from stackoverflow that I used to properly make links properly
    // link to sections within the faq.
    // The method also sanitizes the HTML and allows id attributes to remain
    convertAnchorTags(html: string): SafeHtml {
        // Create temporary element and apply HTML to it.
        const template = this.renderer.createElement('template');
        template.innerHTML = html.trim();

        // Pull all anchor tags from FAQ HTML.
        const anchorNodes: NodeList = template.content.querySelectorAll('a');
        const anchors: Node[] = Array.from(anchorNodes);

        // Loop through each anchor tag and if the href attribute's first character
        // is a '#' change the anchor's href to have correct self-linking URL
        for (const anchor of anchors) {
            // @ts-ignore
            const href: string = (anchor as HTMLAnchorElement).getAttribute(
                'href'
            );
            if (href.indexOf('#') === 0) {
                this.renderer.setProperty(
                    anchor,
                    'href',
                    `p/${this.questionnaireIdService.questionnaireId}/faq${href}`
                );
            }
        }

        // We need to bypass the security here because otherwise HTML elements
        // such as h3 tags with ID attributes have the ID attributes removed.
        // Without doing this, the anchor tags that link to elements on the page
        // wouldn't work as the elements wouldn't be found.
        return this.domSanitizer.bypassSecurityTrustHtml(template.innerHTML);
    }
}
