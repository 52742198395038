<div class="wrapper">
    <div class="text-content" *ngIf="introduction$ | async; let introduction">
        <app-paragraph [text]="introduction"></app-paragraph>
        <div class="inline-wrapper">
            <app-button type="filledPrimary" (appClick)="onSubmit()">
                {{ 'proctor.navigation.start' | translate }}
            </app-button>
        </div>
    </div>
    <app-spinner></app-spinner>
</div>
