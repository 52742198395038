import { Injectable } from '@angular/core';
import { ILogData } from '@shared/models';
import { Observable } from 'rxjs';
import { PATH } from 'src/app/config/path.configs';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor(private readonly restApi: RestApiService) {}

    public logError(
        questionnaireId: string | null = null,
        error: ILogData
    ): Observable<null> {
        return this.restApi.post(
            `${PATH.QUESTIONNAIRE}/${questionnaireId}/${PATH.LOG}`,
            error
        );
    }
}
