import { AbstractControl, FormControl } from '@angular/forms';

export function confirmEmailValidation(confirmEmailInput: string) {
    let confirmEmailControl: AbstractControl;
    let emailControl: FormControl;

    return (control: AbstractControl) => {
        if (!control.parent) {
            return null;
        }

        if (!confirmEmailControl) {
            confirmEmailControl = control;
            emailControl = control.parent.get(confirmEmailInput) as FormControl;
            emailControl.valueChanges.subscribe(() => {
                confirmEmailControl.updateValueAndValidity();
            });
        }

        if (
            emailControl.value.toLocaleLowerCase() !==
            confirmEmailControl.value.toLocaleLowerCase()
        ) {
            return { notMatch: true };
        }

        return null;
    };
}
