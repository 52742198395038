import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { StatusPageComponent } from './status-page.component';

@NgModule({
    declarations: [StatusPageComponent],
    imports: [SharedModule],
    exports: [StatusPageComponent],
})
export class StatusPageModule {}
