import {
    Component,
    Input,
    Output,
    OnDestroy,
    OnInit,
    EventEmitter,
} from '@angular/core';
import {
    IIntroduction,
    ILogData,
    IScreen,
    ISubmitQuestionnaire,
} from '@models';
import { IntroductionService, QuestionnaireService } from '@services';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { StatusType } from '@shared/constants';
import { IDynamicComponent } from '@shared/pages/dynamic-page';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-introduction',
    templateUrl: './introduction.component.html',
    providers: [IntroductionService],
})
export class IntroductionComponent
    implements IDynamicComponent, OnDestroy, OnInit
{
    @Input() screen!: IScreen;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public introduction$: Subject<string> = new Subject<string>();

    constructor(
        public questionnaireIdService: QuestionnaireIdService,
        private introductionService: IntroductionService,
        private questionnaireService: QuestionnaireService,
        private loggingService: LoggingService
    ) {}

    ngOnInit(): void {
        this.introductionService
            .getIntroduction(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: IIntroduction) => {
                    if (response && response.text) {
                        this.introduction$.next(response.text);
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    public handleNavigateForward(event: any) {
        this.navigateForward.emit(event);
    }

    public onSubmit() {
        const body: ISubmitQuestionnaire = {
            id: this.questionnaireIdService.questionnaireId,
            status: StatusType.InProgress,
            userAgent: window.navigator.userAgent,
        };

        this.questionnaireService
            .changeStatusQuestionnaire(
                this.questionnaireIdService.questionnaireId,
                body
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.handleNavigateForward('introduction');
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.introduction$.unsubscribe();
    }
}
