import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { IAppConfiguration } from '.';

@Injectable({
    providedIn: 'root',
})
export class EnvConfigurationService {
    private readonly configUrl = 'assets/config/config.json';
    public configuration$!: Observable<IAppConfiguration>;
    private http!: HttpClient;
    private routingImproved: boolean = window['routingImproved'];

    constructor(private handler: HttpBackend) {
        this.http = new HttpClient(this.handler);
    }

    public setConfig(): Observable<IAppConfiguration> {
        if (!this.configuration$) {
            this.configuration$ = this.http
                .get<IAppConfiguration>(this.configUrl)
                .pipe(shareReplay(1));
        }

        return this.configuration$;
    }

    public getRoute(questionnaireId: string) {
        const route = this.routingImproved ? `/s/${questionnaireId}` : '/';
        return route;
    }

    public getRouteIntroduction(questionnaireId: string) {
        return '/';
    }
}
