import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { IGratitude, ILogData, IScreen } from '@models';
import { QuestionnaireService } from '@services';
import { LoggingService } from '@services/logging.service';
import { QuestionnaireIdService } from '@services/questionnaireId.service';
import { IDynamicComponent } from '@shared/pages/dynamic-page';
import { AsyncSubject, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-gratitude',
    templateUrl: './gratitude.component.html',
})
export class GratitudeComponent
    implements IDynamicComponent, OnDestroy, OnInit
{
    @Input() screen: IScreen;
    @Output() navigateBack = new EventEmitter();
    @Output() navigateForward = new EventEmitter();

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public gratitude$: AsyncSubject<string> = new AsyncSubject<string>();
    public isAdditionalContent: boolean = false;
    public showScores = false;
    public currentVideoId: string;
    public altVideoText: string;

    constructor(
        public questionnaireIdService: QuestionnaireIdService,
        public questionnaireService: QuestionnaireService,
        private loggingService: LoggingService
    ) {}

    ngOnInit(): void {
        this.questionnaireService
            .getGratitude(this.questionnaireIdService.questionnaireId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: IGratitude) => {
                    if (response) {
                        const link = response?.resources?.video?.link;
                        this.isAdditionalContent = !!link;

                        if (link) {
                            const params = new URL(link).searchParams;
                            this.currentVideoId = params.get('v');
                        }

                        this.altVideoText = response?.resources?.video?.text;

                        if (!this.isAdditionalContent) {
                            this.showScores = true;
                        }

                        if (response.text) {
                            this.gratitude$.next(response.text);
                            this.gratitude$.complete();
                        }
                    }
                },
                error: error => {
                    const body: ILogData = {
                        screenId: this.screen?.id ? this.screen.id : null,
                        userAgent: window.navigator.userAgent,
                        details: error,
                        submitTimestamp: new Date().toJSON(),
                    };

                    this.loggingService.logError(
                        this.questionnaireIdService.questionnaireId,
                        body
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.gratitude$.unsubscribe();
    }

    public onSubmit(): boolean {
        if (this.currentVideoId) {
            const body: ILogData = {
                screenId: this.screen?.id ? this.screen.id : null,
                userAgent: window.navigator.userAgent,
                details: {
                    videoId: this.currentVideoId,
                },
                submitTimestamp: new Date().toJSON(),
            };

            this.loggingService
                .logError(this.questionnaireIdService.questionnaireId, body)
                .pipe(takeUntil(this.destroy$))
                .subscribe();
        }

        return (this.showScores = true);
    }
}
