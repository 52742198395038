import { Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '@shared/services';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
    constructor(public loader: LoadingService) {}
}
