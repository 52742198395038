export const PATH = {
    METADATA: '/metadata',
    QUESTIONNAIRE: '/questionnaire',
    QUESTION: 'question',
    BRANDING: 'branding',
    ANSWER: 'answer',
    SCREEN: 'screen',
    INTRODUCTION: 'introduction',
    CONTACT_INFORMATION: 'contact-information',
    DECLINE_SURVEY: 'decline-survey',
    SMS_LANDING_PAGE: 'sms-landing',
    UPDATE_CONTACT_PREFERENCE: 'update-contact-preference',
    SPLASH: 'splash',
    GRATITUDE: 'gratitude',
    SCORE: 'score',
    LOG: 'log',
    RESEARCH_SUBJECT_INFO: 'research-subject-info',
    SUBJECT_CONSENT: 'subject-consent',
    CONSENT: 'consent',
    RESEARCH_QUESTION: 'research-question',
};
