export class AnswerBase<T> {
    value: T | undefined;
    key: string;
    label: string;
    required: boolean;
    disabled: boolean;
    order: number;
    controlType: string;
    isMultiselect: boolean;
    type: string;
    placeholder: string;
    max: number;
    min: number;
    rangeOptions: {
        vertical?: boolean;
        minValueLabel?: string;
        maxValueLabel?: string;
        notApplicableOption?: string;
        notApplicableAnswerKey?: string;
        showValuesStep?: number;
        smallTicksStep?: number;
        mediumTicksStep?: number;
        largeTicksStep?: number;
        tickLocation?: string;
        showTicks?: boolean;
        showTip?: boolean;
        valueStep?: number;
        showCurrentValue?: boolean;
        showCurrentValueText?: string;
        eq5dCompliant?: boolean;
    };
    options: {
        key: string;
        value: string;
        isCommentAvailable?: boolean;
        isCommentMandatory?: boolean;
        checked?: boolean;
    }[];

    constructor(
        options: {
            value?: T;
            key?: string;
            label?: string;
            required?: boolean;
            order?: number;
            controlType?: string;
            type?: string;
            placeholder?: string;
            max?: number;
            min?: number;
            isMultiselect?: boolean;
            disabled?: boolean;
            rangeOptions?: {
                vertical?: boolean;
                minValueLabel?: string;
                maxValueLabel?: string;
                notApplicableOption?: string;
                notApplicableAnswerKey?: string;
                showValuesStep?: number;
                smallTicksStep?: number;
                mediumTicksStep?: number;
                largeTicksStep?: number;
                tickLocation?: string;
                showTicks?: boolean;
                showTip?: boolean;
                valueStep?: number;
                showCurrentValue?: boolean;
                showCurrentValueText?: string;
                eq5dCompliant?: boolean;
            };
            options?: {
                key: string;
                value: string;
                isCommentAvailable?: boolean;
                isCommentMandatory?: boolean;
                checked?: boolean;
            }[];
        } = {}
    ) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order || 1;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.placeholder = options.placeholder || '';
        this.max = options.max || 0;
        this.min = options.min || 0;
        this.options = options.options || [];
        this.rangeOptions = options.rangeOptions || {};
        this.isMultiselect = options.isMultiselect || false;
        this.disabled = options.disabled || false;
    }
}
