<div class="wrapper">
    <div
        class="consent"
        *ngIf="researchSubjInfo$ | async; let researchSubjInfo"
    >
        <app-paragraph [text]="researchSubjInfo?.text"></app-paragraph>
        <mat-card class="scrolling">
            <table class="table">
                <tr>
                    <td class="table-label">
                        {{ researchSubjInfo?.titleLable }}
                    </td>
                    <td
                        class="table-value"
                        [innerHtml]="researchSubjInfo?.title"
                    ></td>
                </tr>
                <tr>
                    <td class="table-label">
                        {{ researchSubjInfo?.protocolNoLabel }}
                    </td>
                    <td
                        class="table-value"
                        [innerHtml]="researchSubjInfo?.protocolNo"
                    ></td>
                </tr>
                <tr>
                    <td class="table-label">
                        {{ researchSubjInfo?.sponsorLabel }}
                    </td>
                    <td
                        class="table-value"
                        [innerHtml]="researchSubjInfo?.sponsor"
                    ></td>
                </tr>
                <tr>
                    <td class="table-label">
                        {{ researchSubjInfo?.investigatorLabel }}
                    </td>
                    <td
                        class="table-value"
                        [innerHtml]="researchSubjInfo?.investigator"
                    ></td>
                </tr>
                <tr>
                    <td class="table-label">
                        {{ researchSubjInfo?.sitesLabel }}
                    </td>
                    <td
                        class="table-value"
                        [innerHtml]="researchSubjInfo?.sites"
                    ></td>
                </tr>
            </table>
        </mat-card>
        <div class="inline-wrapper">
            <app-button
                type="filledPrimary"
                [icon_name]="'navigate_next'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="true"
                [isIconBeforeLabel]="false"
                (appClick)="onSubmit()"
            >
                Next
            </app-button>
            <app-button
                type="outlinedPrimary"
                [icon_name]="'navigate_before'"
                [isWhiteIcon]="true"
                [isOutlined]="true"
                [isIconAfterLabel]="false"
                [isIconBeforeLabel]="true"
                (appClick)="handleNavigateBack($event)"
            >
                Back
            </app-button>
        </div>
    </div>
</div>
